<template>
  <section class="section is-paddingless">
    <div v-if="contentLoaded"
         class="grid-container flex-grid"
         :style="{'height':  height}">
      <virtual-scroll-list
        :grid-name="gridName"
        :items-found="count"
        :table-columns="tableColumns"
        :table-data="items"
        :title="footerTranslation"
        :wrapper-is-loading="isLoading"
        :is-mobile="isMobile"
        :is-library="true"
        :hide-footer="true"
        :hide-empty-forms-message="true"
        @tobottom="getMoreResults"
        @useWidgetNavToContent="handleWidgetNav">
        <template #filterAndAdd>
          <library-where-used-virtual-filter-bar
            :grid-name="gridName"
            :filters="virtualFilters"
            :show-page-book-select="showPageBookSelect"
            :is-mobile="isMobile"
            :partFilter="partFilter"
            @updatePartFilter="updatePartFilter"
          />
        </template>
      </virtual-scroll-list>
    </div>
    <article class="has-text-weight-bold padme-left-bottom footer">
      {{ `${itemsTotal} ` + footerTranslation }}
    </article>
  </section>
</template>

<script>
import { computed, toRaw } from 'vue'
import { mapState, mapActions, useStore } from 'vuex'
import VirtualScrollList from '@/components/common/VirtualScrollList'
import LibraryWhereUsedVirtualFilterBar from '@/components/library/content/whereused/LibraryWhereUsedVirtualFilterBar'
import { Pagination } from '@/interfaces/global'
import { search } from '@/controllers/global'
import { VirtualListFilters } from '@/classes/virtualListObjects'
import RaisedCard from '@/components/common/RaisedCard'
import BSelect from "@/buefy/src/components/select/Select";
import debounce from '@/plugins/debounce'
import { WidgetTypes } from '@/interfaces/global'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useOpenToContent } from '@/components/widgets/composables/useOpenToContent'

export default {
  name: 'WhereUsed',
  mixins: [debounce],
  setup () {
    const store = useStore()
    const {
      mapDtoAndNavToWidgetContent,
      entityIdToIdDtoMap,
      clearContentState
    } = useWidgetNavigator()
    const { setTocQuery } = useOpenToContent()

    const isWidget = computed(() => store.getters['widgets/isWidget'])
    const widgetType = computed(() => store.getters['widgets/widgetType'])
    const isLibraryWidget = computed(() => isWidget.value
      && widgetType.value === WidgetTypes.library)
    const content = computed(() => store.state.content)

    function handleWidgetNav (statefulDto) {
      const dto = toRaw(statefulDto.field)
      if (isLibraryWidget.value) {
        if (content.value?.type === 'part') {
          store.dispatch('search/setTocQuery', `"${content.value?.partNumber}"`)
          setTocQuery(`"${content.value?.partNumber}"`)
        } else if (content.value?.type === 'page') {
          setTocQuery(`"${content.value?.originalName}"`)
          store.dispatch('search/setTocQuery', `"${content.value?.originalName}"`)
        }
        clearContentState()
        mapDtoAndNavToWidgetContent(dto, entityIdToIdDtoMap)
      }
    }

    return { isWidget, widgetType, handleWidgetNav, content }
  },
  components: {
    BSelect,
    RaisedCard,
    VirtualScrollList,
    LibraryWhereUsedVirtualFilterBar
  },
  props: {
    height: {
      type: String,
      default: '100%'
    },
  },
  data () {
    return {
      count: 0,
      isLoading: false,
      items: [],
      offsetNextCursor: 0,
      searchInstance: 0,
      screenWidth: window.innerWidth,
      partFilter: 'ALL'
    }
  },
  watch: {
    searchParam (updated, previous) {
      if (JSON.stringify(updated) === JSON.stringify(previous)) return
      this.reset()
    },
    searchFilters (updated, previous) {
      if (JSON.stringify(updated) === JSON.stringify(previous)) return
      this.reset()
    },
    activeColumnAndSort (updated, previous) {
      if (JSON.stringify(updated) === JSON.stringify(previous)) return
      this.reset()
    },
    contentLoaded (updated, previous) {
      if (!previous && updated && !this.isLoading) {
        this.reset()
      }
    },
    isMobile (updated, previous) {
      if (previous != updated) {
        const defaultColumn = this.isMobile ? 'name' : 'identifier'
        this.setGridSort({
          grid: this.gridName,
          activeColumn: defaultColumn,
          sortInvert: false
        })
      }
    },
    partFilter(updated, previous) {
      if (previous !== updated && !this.isLoading) {
        this.reset()
      }
    }
  },
  computed: {
    ...mapState({
      contentLoaded: ({ content }) => content.isLoaded,
      tenantKey: (state) => state.user.tenantKey,
      hidePagesInSearch: (state) => state.user.hidePagesInSearch,
      hideBooksInSearch: (state) => state.user.hideBooksInSearch
    }),
    activeColumnAndSort () {
      return '' + this.sortField + this.sortInvert
    },
    virtualFilters () {
      return new VirtualListFilters(
        {
          tagsMatching: {
            indexed: (i) => typeof i === 'number',
            applicableTypes: this.applicableTagTypes
          },
          hasSearchTerm: true
        }
      )
    },
    applicableTagTypes () {
      if (this.content.type === 'part') {
        return ['MEDIA', 'PAGE']
      } else {
        return ['MEDIA']
      }
    },
    searchParam () {
      return this.$store.getters['grids/filterTerm'](this.gridName) ?? ''
    },
    searchFilters () {
      const result = { ...this.$store.getters['grids/searchFilters'](this.gridName) }
      if (result.hasOwnProperty('tag_name')) delete result.tag_name
      if (result.hasOwnProperty('tag_value')) delete result.tag_value
      if (result.hasOwnProperty('sortField')) delete result.sortField
      if (result.hasOwnProperty('filterTerm')) delete result.filterTerm
      return result
    },
    hasSearchFilters () {
      return Object.keys(this.searchFilters).length
    },
    gridName () {
      return 'whereUsedLibraryGrid'
    },
    tableColumns () {
      return [
        {
          columnClass: 'column',
          field: 'name',
          translation: this.$t('name'),
          type: 'thumbnailWithText',
          isLink: this.isWidget
            ? (this.widgetType === WidgetTypes.library)
            : true,
          isHidden: !this.isMobile,
          isSortable: true,
          getToRoute: this.getToRouteUrl,
          thumbnailType: this.baseThumbnailType,
          url: `https://static.digabit.com/${this.baseThumbnailType === 'page' ? 'page' : 'book'}.svg`,
          getThumbnailUrl: source => source?.thumbnailUrl ?? `https://static.digabit.com/${source?.entityType.toLowerCase()}.svg`,
          getThumbnailType: source => source?.entityType
        },
        {
          centered: true,
          columnClass: 'column-shrink',
          columnsAssigned: true,
          field: 'icon',
          isLink: false,
          isHidden: this.isMobile,
          isSortable: false,
          thumbnailType: this.baseThumbnailType,
          url: `https://static.digabit.com/${this.baseThumbnailType === 'page' ? 'page' : 'book'}.svg`,
          getThumbnailUrl: source => source?.thumbnailUrl ?? `https://static.digabit.com/${source?.entityType.toLowerCase()}.svg`,
          getThumbnailType: source => source?.entityType
        },
        {
          columnClass: 'column',
          field: 'identifier',
          isLink: this.isWidget
            ? (this.widgetType === WidgetTypes.library)
            : true,
          isHidden: this.isMobile,
          isSortable: true,
          translation: this.$t('identifier'),
          getToRoute: this.getToRouteUrl
        },
        {
          columnClass: 'column',
          field: 'name',
          translation: this.$t('name'),
          isHidden: this.isMobile,
          isLink: this.isWidget
            ? (this.widgetType === WidgetTypes.library)
            : true,
          isSortable: true,
          getToRoute: this.getToRouteUrl
        },
        {
          columnClass: 'column',
          field: 'mediaType',
          isHidden: this.isMobile,
          translation: this.$tc('type', 1),
          isLink: false,
          type: 'method',
          isSortable: true,
          conversionMethod: this.displayType
        }
      ]
    },
    footerTranslation () {
      const num = this.itemsTotal === 1 ? 1 : 2
      if (this.content.type === 'part')
        return this.$tc('result', num)
      else
        return this.$tc('media', num)
    },
    sortField () {
      let field = this.$store.getters['grids/activeColumn'](this.gridName) ?? 'identifier'
      if (this.isMobile) field = 'name'
      return field === 'identifier' ? this.identifierSortKey : field
    },
    sortInvert () {
      return this.$store.getters['grids/sortInvert'](this.gridName) ?? false
    },
    itemsTotal () {
      return this.$store.getters['grids/totalCount'](this.gridName)
    },
    excludeFilter () {
      if (this.content.type === 'part') {
        if (this.partFilter === 'BOOK')
          return { entity_type: '-Page' }
        else if (this.partFilter === 'PAGE')
          return { entity_type: '-Media' }
        else return {}
      }
      return {}
    },
    filter () {
      if (this.content.type === 'page') {
        return {
          entity_type: 'Media',
          fk_page_id: this.content.id
        }
      } else if (this.content.type === 'part') {
        return {
          fk_part_id: this.content.id
        }
      } else {
        // should not happen
        return { entity_type: 'Media' }
      }
    },
    sortClause () {
      if (!this.translatedSortField) return {}
      return {
        [this.sortInvert ? 'sort_desc' : 'sort_asc']: this.translatedSortField
      }
    },
    translatedSortField () {
      switch (this.sortField) {
        case 'name':
          return 'name_sort_' + this.$i18n.locale.replace('-', '_')
        case 'mediaType':
          return 'entity_type'
        default:
          return this.sortField
      }
    },
    identifierSearchKey () {
      return 'identifier'
    },
    identifierSortKey () {
      return 'identifier_sort'
    },
    showPageBookSelect () {
      if (this.content.type !== 'part') return false
      else return (!(this.hideBooksInSearch || this.hidePagesInSearch))
    },
    isMobile () {
      const mobile = this.screenWidth <= 1024
      return mobile
    },
    query () {
      switch (this.content.type) {
        case 'part':
          return '?q="' + this.content.partNumber + '"'
        case 'page':
          return '?q="' + this.content.originalName + '"'
        default:
          return ''
      }
    }
  },
  debounceMethods: {
    reset: 500,
    getMoreResults: 500
  },
  methods: {
    ...mapActions({
      addGridToStore: 'grids/addGrid',
      removeGrid: 'grids/removeGrid',
      setItemsTotal: 'grids/setTotalCount',
      setPagination: 'grids/setPagination',
      getContent: 'content/getContent',
      setGridSort: 'grids/setGridSort',
      setWhereUsedReceivedCount: 'content/setWhereUsedReceivedCount'
    }),
    async getMoreResults () {
      if (this.itemsTotal <= this.count) return
      await this.loadMore()
    },
    async loadMore () {
      if (!this.contentLoaded) return
      const searchId = ++this.searchInstance
      try {
        if (this.offsetNextCursor === 0) {
          this.items = []
        }
        const params = {
          ...this.filter,
          ...this.excludeFilter,
          ...this.searchFilters,
          ...this.sortClause,
          q: (this.searchParam && this.searchParam.length > 0) ? this.searchParam + "* OR " + this.searchParam : '',
          includeTagRollups: (this.searchParam && this.searchParam.length > 0) ? true : false,
          qf: ['name_', this.identifierSearchKey],
          limit: 100,
          offset: this.offsetNextCursor
        }
        const {
          data,
          headers
        } = await search(params)
        //console.log('JSON ' + JSON.stringify(params))
        if (searchId !== this.searchInstance) return

        this.items.push(...data.items)
        const total = Number.parseInt(headers['x-count'])
        this.count = this.items.length
        await this.setPagination({
          grid: this.gridName,
          pagination: new Pagination(headers)
        })
        this.setItemsTotal({
          value: total,
          grid: this.gridName
        })
        this.offsetNextCursor = this.count
        this.setWhereUsedReceivedCount(total)
        this.$emit('whereUsedLoaded')
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async reset () {
      this.isLoading = true
      this.offsetNextCursor = 0
      this.count = 0
      await this.loadMore()
      this.isLoading = false
    },
    displayType (field) {
      if (!field) {
        return this.$tc('page', 1)
      } else {
        return this.$tc(field, 1)
      }
    },
    getToRouteUrl (source) {
      if (source.entityType === 'page') {
        return `/page/${source.entityId}` + this.query
      } else {
        return `/book/${source.entityId}` + this.query
      }
    },
    handleResize () {
      this.screenWidth = window.innerWidth
    },
    updatePartFilter(value) {
      this.partFilter = value
    }
  },
  async created () {
    await this.addGridToStore({
      name: this.gridName,
      defaultColumn: this.isMobile ? 'name' : 'identifier'
    })
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.reset()
  },
  beforeDestroy () {
    this.removeGrid(this.gridName)
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.grid-container {
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

.flex-grid {
  display: flex;
  flex-flow: column;
  flex: 1;
}

.padme-left-bottom {
  padding: 1.25rem 1rem;
  border-top: lightgray 1px solid;
}
.content-select {
  display: block;
  float: right;
}

.footer {
  min-width: 5em;
}
</style>
