import { ActionContext, MutationTree, GetterTree } from 'vuex'
import partComments from './partComments'
import { WidgetConfig, WidgetTypes } from '@/interfaces/global/widgets'

type WidgetState = {
  widgetConfig: WidgetConfig|null
}
type Context = ActionContext<any, any>
export enum MutationTypes {
  SET_WIDGET_CONFIG = "SET_WIDGET_CONFIG"
}

const state: WidgetState = {
  widgetConfig: null
}

const getters: GetterTree<WidgetState, any> = {
  hasMediaEntryPoint(state) {
    return !!state.widgetConfig?.mediaIdentifier
  },
  targetUri(state) {
    return state.widgetConfig?.targetUri
  },
  isWidgetCartEnabled(state, getters) {
    return (state.widgetConfig?.enableCart ?? false)
      && getters.isWidget
  },
  browseFlowNodeId(state) {
    return state.widgetConfig?.browseFlowNodeId
  },
  mediaIdentifier(state) {
    return state.widgetConfig?.mediaIdentifier
  },
  hasEnabledTags(state, getters) {
    return (state.widgetConfig?.enablePartTags ?? false)
      && getters.isWidget
  },
  hasEnabledComments(state, getters) {
    return (state.widgetConfig?.enablePartComments ?? false)
    && getters.isWidget
  },
  isWidget(state, getters, rootState) {
    return rootState.auth.isWidget
  },
  widgetType(state) {
    return state.widgetConfig?.widgetType as WidgetTypes
      ?? WidgetTypes.loading
  },
  showToc(state) {
    return state.widgetConfig?.showTableOfContents ?? true
  },
  pageHashKey(state) {
    return state.widgetConfig?.pageHashKey
  },
  partNumber(state) {
    return state.widgetConfig?.partNumber
  },
  hasInfoLandingPage(state, getters) {
    return (state.widgetConfig?.enableInformationLandingPage ?? false)
      && getters.isWidget
  }
}

const actions = {
  initialize({ commit, dispatch }: Context, config: WidgetConfig) {
    commit(MutationTypes.SET_WIDGET_CONFIG, config)
  },
  setConfig({ commit}: Context, config: WidgetConfig) {
    commit(MutationTypes.SET_WIDGET_CONFIG, config)
  }
}

const mutations: MutationTree <WidgetState> = {
  [MutationTypes.SET_WIDGET_CONFIG] (state, payload: WidgetConfig) {
    state.widgetConfig = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    partComments
  }
}
