<template>
  <div class="control"
       :class="{ 'is-expanded': expanded }">
    <span class="select"
          :class="{
            'is-danger': isDanger,
            'is-fullwidth': expanded,
            'is-multiple': multiple,
            'is-rounded': rounded,
            'is-empty': value === null
          }">
      <select v-model="computedValue"
              :disabled="disabled">
        <template v-if="placeholder">
          <option
              v-if="computedValue == null"
              :value="null"
              disabled
              hidden>
            {{ placeholder }}
          </option>
        </template>
        <slot/>
      </select>
    </span>

  </div>
</template>

<script setup lang="ts">
/*
 * Intended as replacement for Buefy Select.vue AKA b-select.
 * Uses simpler design than b-select in that there is no support for
 * placeholder option element via a conditional template tag and
 * placeholder prop. Just make use of slot to apply placeholder
 * option along with select-able options.
 * See SettingInput.vue for implementation example.
 */
import { computed } from "vue"

interface Props {
  expanded?: boolean,
  disabled?: boolean,
  multiple?: boolean,
  rounded?: boolean,
  value: string | number | boolean 
    | Object | Array<any> | Function
    | null,
  isDanger?: boolean,
  placeholder: string
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: ""
})

const emit = defineEmits(["input"])

const computedValue = computed({
  get: () => props.value,
  set(updatedValue) {
    emit("input", updatedValue)
  }
})
</script>
