import http from '@/http'
import i18n from '@/locales'
import { getRoute } from "@/router/getRoute"
import { postError } from '@/helpers/notification'

const JOBS_RECEIVED = 'JOBS_RECEIVED'
const JOBS_UPDATE_SEARCH_OFFSET = 'JOBS_UPDATE_SEARCH_OFFSET'
const JOBS_UPDATE_SEARCH_LIMIT = 'JOBS_UPDATE_SEARCH_LIMIT'

const state = {
  items: [],
  offset: 0,
  limit: 10,
  total: 0
}

const actions = {
  async getJobs({commit, rootState, state}) {
    const route = getRoute()
    const name = []
    switch (route.query.type) {
      case 'EXPORT':
        name.push('printJob')
        name.push('bulkExportJob')
        name.push('shoppingCartExportJob')
        break
      case 'PUBLISHER':
        name.push('publishDocumentsJob')
        name.push('publishDraftPageJob')
        //There are three bulkPageInsertJob names in the code
        name.push('bulkPageInsertJob')
        name.push('bulkInsertPageIntoBooksParentJob')
        name.push('publishAttachmentJob')
        name.push('publishDocumentsJobGroup')
        name.push('publishThumbnailerJob')
        name.push('turboPublishDraftPageJob')
        break
      case 'THUMBNAIL':
        name.push('thumbnailerJob')
        break
      case 'ATTACHMENT':
        name.push('publishAttachmentJob')
        break
      case 'IMAGE':
        name.push('imageOptimizerJob')
        break
      case 'INDEX':
        name.push('updateIndexJob')
        name.push('indexQueueJob')
        name.push('optimizeIndexJob')
        name.push('rebuildIndexJob')
        name.push('tenantIndexQueueJob')
        break
      default:
    }

    const params = {
      limit: state.limit,
      offset: state.offset * state.limit,
      name,
      state: route.query.state || undefined
    }

    try {
      const { headers, data } = await http.get('jobs', {params})
      commit(JOBS_RECEIVED, { items: data, meta: headers })
    } catch (err) {
      const response = err.response
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      })
    }
  },
  setJobsPage({commit, dispatch}, page) {
    commit(JOBS_UPDATE_SEARCH_OFFSET, {
      offset: Math.max(page, 0)
    })
    dispatch('getJobs')
  },
  setJobsResultsPerPage({commit, dispatch}, limit) {
    commit(JOBS_UPDATE_SEARCH_LIMIT, {limit})
    dispatch('getJobs')
  }
}

const mutations = {
  [JOBS_RECEIVED](state, {items, meta}) {
    state.items = items
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0
  },
  [JOBS_UPDATE_SEARCH_OFFSET](state, {offset}) {
    state.offset = offset
  },
  [JOBS_UPDATE_SEARCH_LIMIT](state, {limit}) {
    state.limit = limit
    state.offset = 0
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
