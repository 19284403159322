import http from '@/http';
import router from '@/router';
import i18n from '@/locales';
import { notify } from "@kyvg/vue3-notification"
import { getRoute } from "@/router/getRoute"

const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RESET_PASSWORD = 'RESET_PASSWORD';

const state = {
  sendingPassword: false,
  sendingPasswordError: '',
  resettingPassword: false,
  resettingPasswordError: ''
};

const actions = {
  async forgotPassword ({ commit, dispatch }, { username, tenantKey }) {
    const resolve = async function () {
      notify({
        title: i18n.global.t('forgotPassword'),
        text: i18n.global.t('forgotPasswordSuccess'),
        type: 'success'
      })
      commit(FORGOT_PASSWORD, { sendingPassword: false })
      dispatch('navigateToLogin')
    }
    try {
      const formData = new FormData()
      formData.append('username', username)
      if(tenantKey) {
        formData.append('tenantKey', tenantKey)
      }
      commit(FORGOT_PASSWORD, {sendingPassword: true})
      await http.post('password-reset/request', formData)
      await resolve()
    } catch (e) {
      await resolve()
      console.log('error', e)
    }

  },
  async resetPassword ({ commit, dispatch }, { password, username, token, tenantKey }) {
    try {
      const formData = new FormData()
      formData.append('passwordResetToken', token)
      formData.append('username', username)
      formData.append('password', password)
      formData.append('tenantKey', tenantKey)

      commit(RESET_PASSWORD, {resettingPassword: true})
      await http.post('password-reset/complete', formData)

      notify({
        title: i18n.global.t('resetPassword'),
        text: i18n.global.t('updatePasswordMessage'),
        type: 'success'
      });
      dispatch('navigateToLogin', true)
    } catch (error) {
      let message = i18n.global.t('resetPasswordError')
      if (error.response.status === 404) {
        message = i18n.global.t('resetPasswordTokenError')
      }
      commit(RESET_PASSWORD, {resettingPassword: false, error: message})
    }
  },
  navigateToLogin({ state, rootState }, replace = false) {
    const route = getRoute()
    const query = {
      tk: route.query.tk || undefined,
      ok: state.orgKey || undefined
    };

    if (replace) {
      router.replace({ name: 'Login', query })
    } else {
      router.push({ name: 'Login', query })
    }
  },
  async newUserPasswordReset ({ commit, dispatch }, { username, tenantKey }) {
    try {
      const formData = new FormData()
      formData.append('username', username)
      formData.append('tenantKey', tenantKey)
      formData.append('forceReset', true)
      await http.post('password-reset/request', formData)
    } catch (e) {
      console.log('error', e)
    }
  }
};

const mutations = {
  [FORGOT_PASSWORD](state, {sendingPassword, error = ''}) {
    state.sendingPassword = sendingPassword;
    state.sendingPasswordError = error;
  },
  [RESET_PASSWORD](state, {resettingPassword, error = ''}) {
    state.resettingPassword = resettingPassword;
    state.resettingPasswordError = error;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
