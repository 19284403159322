<template>
<div class="field"
     :class="{ 'is-expanded': expanded, 'is-horizontal': isHorizontal }">
  <label v-if="!!label"
         class="label"
         :class="customClass">
    {{ label }}
  </label>
  <template v-if="grouped || groupMultiline || hasAddons">
    <div class="field-body">
      <div class="field"
           :class="{ 'is-grouped': grouped,
                     'is-grouped-multiline': groupMultiline,
                     'has-addons': hasAddons }">
        <slot />
      </div>
    </div>
  </template>
  <template v-else>
    <slot />
  </template>
  <p v-if="slots.subtitle"
     class="help">
    <slot name="subtitle"/>
  </p>
  <p v-if="!!message"
     :class="[ 'help', messageClass ]">
    {{ message }}
  </p>
</div>
</template>

<script setup>
/* Intended replacement for Buefy Field.vue with simpler design
 * In contrast to b-field, warning messages have to be applied
 * to this component as well as its child component.
 * See DetailEditorForm.vue for implementation examples
 * w/BulmaInput.vue as the child component
 */

import { useSlots } from "vue"

const slots = useSlots()

defineProps({
  label: {
    type: String,
    default: ''
  },
  /*
   * If help text is required, it can also be added within BulmaField, like so.
   *   <bulma-field>
   *     <bulma-input/>
   *     <p class="help">{{ message }}</p>
   *    </bulma-field>
   */
  message: {
    type: String,
    default: ''
  },
  messageClass: {
    type: String,
    default: ''
  },
  grouped: Boolean,
  hasAddons: Boolean,
  groupMultiline: Boolean,
  expanded: Boolean,
  customClass: {
    type: String,
    default: ''
  },
  isHorizontal: Boolean
})
</script>
