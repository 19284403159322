import http from "@/http"
import { ProductCategoryDto, NewCategoryDto, ProductCategoryShortDto } from "@/interfaces/admin/productCategory"

type ParamsDto = {
  offset: string,
  filter: string,
  sort: string
}

const PATH = "/admin/product-categories"

export const searchProductCategories = async function(params: ParamsDto) {
  return await http.get(PATH, { params })
}

export const getAllProductCategories = async function() {
  let categories: ProductCategoryShortDto[] = []
  let pageCount, pageNum, count
  const params = {
    offset: 0,
    limit: 100
  }

  do {
    const { data, headers } = await http.get(`${PATH}/all`, { params })
    count = parseInt(headers["x-count"])
    pageCount = parseInt(headers["x-page-count"])
    pageNum = parseInt(headers["x-page-num"])
    params.offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
    categories = categories.concat(data)
  } while (pageNum < pageCount)

  return {
    data: categories
  }
}

export const getProductCategoryById = async function(id: number) {
  return await http.get(`${PATH}/${id}`)
}

export const updateProductCategory = async function(dto: ProductCategoryDto) {
  return http.put(`${PATH}/${dto.id}`, dto)
}

export const deleteProductCategory = async function(id: number) {
  await http.delete(`${PATH}/${id}`)
}

export const createProductCategory = async function(dto: NewCategoryDto) {
  return await http.post(PATH, dto)
}