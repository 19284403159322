import { createStore } from 'vuex'
import { getRoute } from "@/router/getRoute"
import localforage from 'localforage'

import admin from './modules/admin/admin'
import apiKeys from './modules/apiKeys'
import app from './modules/app'
import asyncFetcher from '@/store/modules/asyncFetcher'
import auth from './modules/auth/auth'
import browse from './modules/browse/browse'
import browseFlow from './modules/browseFlow/browseFlow'
import cart from './modules/cart/cart'
import categories from './modules/categories'
import content from './modules/content/content'
import countrycodes from './modules/countrycodes'
import dirtyManager from './modules/dirtyManager'
import draftBom from './modules/draftPage/draftBom'
import draftPage from './modules/draftPage/draftPage'
import draftPageAutoHotpoint from "./modules/draftPage/draftPageAutoHotpoint";
import draftPageVersion from './modules/draftPage/draftPageVersion'
import draftImage from './modules/draftPage/draftImage'
import draftEditor from '@/store/modules/draftPage/draftEditor'
import ecommerceEmailFormats from "@/store/modules/ecommerceEmailFormats";
import expiredPassword from "@/store/modules/auth/expiredPassword";
import forgotpassword from './modules/auth/forgotpassword'
import grids from './modules/grids'
import hoopsWebViewer from '@/store/modules/hoopsWebViewer'
import hotpointLinks from './modules/HotpointLinks'
import jobs from './modules/jobs'
import labels from './modules/labels'
import locales from './modules/locales'
import notification from './modules/notifications/notification'
import notifications from './modules/notifications/notifications'
import order from './modules/orders/order'
import orders from './modules/orders/orders'
import orderFields from './modules/orderFields'
import orderForms from './modules/admin/orderForms/orderForms'
import organization from './modules/organization'
import organizationAddresses from './modules/organizationAddresses'
import organizationTree from './modules/organizationTree'
import organizationUsers from './modules/organizationUsers'
import organizations from './modules/organizations'
import partCodes from './modules/partCodes'
import partCodeLookups from './modules/partCodeLookups'
import partOrderSuggestions from './modules/partOrderSuggestions'
import pendingTags from './modules/pendingTags'
import printSettings from './modules/printSettings'
import productCategory from './modules/productCategory'
import pspdfkey from './modules/pspdf'
import recentlyadded from './modules/recentlyadded'
import recentlyviewed from './modules/recentlyviewed'
import reports from './modules/reports'
import search from './modules/search/search'
import savedCarts from './modules/cart/savedCarts'
import sharedCarts from './modules/cart/sharedCarts'
import statuscodes from './modules/statuscodes'
import suppliers from './modules/suppliers'
import tagEditor from './modules/tagEditor'
import tenantLabels from './modules/tenantLabels'
import tenants from './modules/tenants'
import theme from './modules/theme/theme'
import user from './modules/user/user'
import userGroups from './modules/userGroups'
import users from './modules/users'
import widgets from './modules/widgets/widgets'
import superAdminOrganizations from './modules/superAdmin/superAdminOrganizations'
import { cloneDeep } from 'lodash'
import adminPart3dFile from '@/store/modules/admin/part/adminPart3dFile'
import detailPageLayouts from '@/store/modules/content/detailPageLayouts'
export * from './asyncFetcher'

const LOCALE_CHANGED = 'LOCALE_CHANGED'

const actions = {
  async getCredentials ({ dispatch, commit, rootState }) {
    const credentials = await dispatch('getLocalStorage', { key: 'credentials' })
    if (!rootState.user.credentialsReceived) {
      const route = getRoute()
      Object.assign({}, credentials, route.query)
      commit('user/USER_CREDENTIALS_RECEIVED', { credentials })
    }
  },
  async getLocalStorage (context, { key }) {
    // eslint-disable-next-line no-return-await
    return await localforage.getItem(key)
  },
  async setLocalStorage (context, { key, value }) {
    /*
     * References to reactive data may return Proxy(Object). This
     * is incompatible with localforage. To mitigate these types,
     * this deep-copies the data before storing.
     */
    if (typeof value === 'object') {
      await localforage.setItem(key, cloneDeep(value))
    } else {
      await localforage.setItem(key, value)
    }
  },
  async removeLocalStorage (context, { key }) {
    await localforage.removeItem(key)
  },
  async localeChanged ({ commit, dispatch }) {
    dispatch('user/getTenantLabels')
    commit(LOCALE_CHANGED)

    dispatch('cart/getCartColumns')
    dispatch('content/getBomColumns')
  }
}

const mutations = {
  [LOCALE_CHANGED] (state) {
    state.categories.isLoaded = false
    state.statuscodes.isLoaded = false
    state.search.filters.isLoaded = false
    state.browse.search.filters.isLoaded = false
    state.orders.filters.isLoaded = false
    state.organizations.isLoading = false
    state.cart.columns.isLoaded = false
    state.content.bom.columns.isLoaded = false
    state.content.bom.illustration = undefined
    state.content.toc.isLoaded = false
    state.content.isLoaded = false
    state.content.toc.book = undefined
  }
}

export const modules = {
  admin,
  adminPart3dFile,
  apiKeys,
  app,
  asyncFetcher,
  auth,
  browse,
  browseFlow,
  cart,
  categories,
  content,
  countrycodes,
  dirtyManager,
  draftBom,
  draftPage,
  draftPageAutoHotpoint,
  draftPageVersion,
  draftImage,
  draftEditor,
  ecommerceEmailFormats,
  expiredPassword,
  forgotpassword,
  grids,
  hoopsWebViewer,
  hotpointLinks,
  jobs,
  labels,
  locales,
  notification,
  notifications,
  order,
  orders,
  orderFields,
  orderForms,
  organization,
  organizationAddresses,
  organizationTree,
  organizationUsers,
  organizations,
  partCodes,
  partCodeLookups,
  partOrderSuggestions,
  pendingTags,
  printSettings,
  productCategory,
  pspdfkey,
  recentlyadded,
  recentlyviewed,
  reports,
  search,
  savedCarts,
  sharedCarts,
  statuscodes,
  suppliers,
  tagEditor,
  tenantLabels,
  tenants,
  theme,
  user,
  userGroups,
  users,
  widgets,
  superAdminOrganizations,
  detailPageLayouts
}

export const store = createStore({
  modules,
  actions,
  mutations,
  plugins: []
})
