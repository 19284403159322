import http from '@/http'

export const getTagNames = async (type = '') => {
  let url = 'admin/tag-name'

  if (type) {
    url += `?applicableType=${type}`
  }
  return http.get(url).then(res => res.data)
}

// /admin/tag-name endpoint will paginate results & only return 1st 100
export const fetchAllTags = async function(type) {
  const url = `admin/tag-name${type && `?applicableType=${type}`}`
  const tags = []
  const params = { offset: 0, sort: 'name:ASC' }
  let pageCount, pageNum

  do {
    const {data, headers} = await http.get(url, {params});
    pageCount = parseInt(headers['x-page-count'])
    pageNum = parseInt(headers['x-page-num'])
    params.offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
    tags.push(...data)
  } while (pageNum < pageCount)

  return tags
}

export const fetchTagNames = async function() {
  return await http.get('/tag-names').then(res => res.data)
}

export const fetchUserTagNames = async function() {
  const data  = await fetchTagNames()
  const tags = data.filter((tagName) => tagName.applicableTypes?.includes('USER'))
  tags.filter((t) => !t.translatedName).forEach((t) => {
    t.translatedName = t.name
  })
  tags.sort((a, b) => a.translatedName.localeCompare(b.translatedName))
  tags.sort((a, b) => a.displayOrder - b.displayOrder)
  return tags
}
