import { ActionContext, MutationTree, GetterTree, Getter } from 'vuex'
import { ProductCategoryShortDto } from '@/interfaces/admin/productCategory'
import { getAllProductCategories } from "@/controllers/admin/productCategory"

interface State {
  productCategories: ProductCategoryShortDto[]
}

type Context = ActionContext<State, any>

enum Mutations {
  SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES"
}

const state = {
  productCategories: []
}

const getters: GetterTree<State, any> = {
  categoriesInAlphaOrder(state) {
    return state.productCategories.toSorted((a, b) => {
      return (a.displayName > b.displayName) ? 1 : -1
    })
  }
}

const actions = {
  async getProductCategories({ commit }: Context) {
    try {
      const { data } = await getAllProductCategories()
      commit(Mutations.SET_PRODUCT_CATEGORIES, data)
    } catch(e) {
      console.error(e)
    }
  }
}

const mutations: MutationTree<State> = {
  [Mutations.SET_PRODUCT_CATEGORIES](
    state, 
    list: ProductCategoryShortDto[]
  ) {
    state.productCategories = list
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}