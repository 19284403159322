import http from '@/http';

/**
 * @param {string} type entity type: media,part,page
 * @param {number|string} id tag value id
 * @param {object} value tag values. see table MEDIATAGVALUE for model.
 * @return {promise}
 */
export async function updateTagValue({type, id, value}) {
  return http.put(`/${type}-tags/admin/by-id/${id}`, value).then((res) => res.data);
}

export async function updateTagsFromList({ pendingEdit, type }) {
  let updateSuccess = false
  let updateError = false

  for (let i = 0; i < pendingEdit.length; i++) {
    const element = pendingEdit[i]

    try {
      await updateTagValue({ id: element.id, type, value: element })
    } catch {
      updateError = true
    } finally {
      updateSuccess = true
    }
  }

  return {
    updateError,
    updateSuccess
  }
}
