<template>
  <div class="search-results-group" v-if="hasResults">
    <div class="search-suggestions-header-title is-row">
      <h3 class="header-result-text title-five">{{ headerLabel }}
        <fieldset class="search-count-container">
          <legend class="small-text">{{ $tc('result',2) }}</legend>
          {{ props.count }}
        </fieldset>
      </h3>
      <div class="view-more-link"><a @click="searchContentType(props.contentType)">{{ $t('viewMore') }}</a></div>
    </div>
    <div v-for="contentItem in props.suggestedSearchResults" class="is-row content-pointer">
      <div class="image is-32x32 mr-1"
           @click="selectContent(contentItem, props.contentType)"
      >
        <thumbnail
          :thumbnail-url="getThumbnailUrl(contentItem)"
          :thumbnail-type="contentItem.entityType"
          class="is-square"
        />
      </div>
      <div class="content"
           @click="selectContent(contentItem, props.contentType)"
      >
        <strong><a><span v-html="getHighlightedLocalName(contentItem)"/></a></strong>
        <span v-if="!hideIdentifier"><br><span class="identifier-text">{{ contentLabel }}: <span v-html="getIdentifierMarkup(contentItem)"/></span></span>
        <span v-html="getHighlightForThirdLine(contentItem)"/>
      </div>
      <div v-if="showWhereUsedCountInSearchBar && showWhereUsedButton(contentItem)" class="where-used-button-container">
        <bulma-tooltip  :label="getTooltipLabel(contentItem)"
                        position="is-left"
                        wrap-text
        >
          <button class="button is-primary where-used-button"
                @click="selectWhereUsed(contentItem, props.contentType)">
            <i class="fas fa-location-arrow fa-xs mr-1" />
            <span class="where-used-button-text">{{ $t('whereUsed') }}</span><span class="where-used-button-count">({{contentItem.whereUsedCount}})</span>
          </button>
        </bulma-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import Thumbnail from '@/components/common/Thumbnail';
import { computed } from "vue";
import i18n from "@/locales";
import BulmaTooltip from '@/components/common/BulmaTooltip.vue'

const emit = defineEmits(['selectContent', 'searchContent'])
const store = useStore()
const props = defineProps({
  contentType: {
    type: String,
    required: true
  },
  count: {
    type: Number,
    required: true
  },
  suggestedSearchResults: {
    type: Array,
    required: true
  }
})
const userLocale = computed(() => i18n.global.locale?.replace('-', '_') ?? store.state.user.locale?.replace('-', '_') ?? 'en_US')
const hasResults = computed(() => props.suggestedSearchResults?.length > 0)
const hideIdentifier = computed(() => store.state.user.hideIdentifier && (props.contentType.toLowerCase() !== 'part') && (props.contentType.toLowerCase() !== 'page'))
const showWhereUsedCountInSearchBar = computed(() => store.state.user.showWhereUsedCountInSearchBar)
const headerLabel = computed(() => {
    switch (props.contentType.toLowerCase()) {
      case 'part':
        return i18n.global.tc('part', 2)
      case 'page':
        return i18n.global.tc('page', 2)
      case 'book':
        return i18n.global.tc('book', 2)
      case 'external':
        return i18n.global.tc('external', 2)
      case 'document':
        return i18n.global.tc('document', 2)
      case 'image':
        return i18n.global.tc('image', 2)
      case 'video':
        return i18n.global.tc('video', 2)
      case 'microsite':
        return i18n.global.tc('microsite', 2)
      default:
        return ''
    }
  }
)
const contentLabel = computed(() => {
    switch (props.contentType.toLowerCase()) {
      case 'part':
        return i18n.global.t('partNumber')
      case 'page':
        return i18n.global.t('pageFilename')
      case 'book':
      case 'external':
      case 'document':
      case 'image':
      case 'video':
      case 'microsite':
        return i18n.global.t('identifier')
      default:
        return ''
    }
  }
)

const getTooltipLabel = (contentItem) => {
  return i18n.global.t('whereUsed')  + " (" + contentItem.whereUsedCount + ")"
}

const showWhereUsedButton = (contentItem) => {
  return ((props.contentType.toLowerCase() === 'part' || props.contentType.toLowerCase() === 'page') && (contentItem.whereUsedCount &&  contentItem.whereUsedCount > 0))
}

const getHighlightedLocalName = (contentItem) => {
  const highlightedFields = contentItem?.highlightedFields
  if (!highlightedFields) return ''
  else {
    const values = highlightedFields["name_" + userLocale.value]
    return values ? values[0] : contentItem.name
  }
}

const getHighlightedLocalDescription = (contentItem) => {
  const highlightedFields = contentItem?.highlightedFields
  if (!highlightedFields) return ''
  else {
    const values = highlightedFields["description_" + userLocale.value]
    return values ? values[0] : ''
  }
}

const getHighlightedIdentifier = (contentItem) => {
  const highlightedFields = contentItem?.highlightedFields
  if (!highlightedFields) return ''
  else {
    const values = highlightedFields["identifier"]
    return values ? values[0] : ''
  }
}

const getIdentifierMarkup = (contentItem) => {
  const highlightedText = getHighlightedIdentifier(contentItem)
  if (highlightedText != '') {
    return highlightedText
  }
  else {
    return contentItem?.identifier
  }

}

const getHighlightedTag = (contentItem) => {
  const highlightedFields = contentItem?.highlightedFields
  if (!highlightedFields) return ''
  else {
    const keys = Object.keys(highlightedFields)
    const key = keys.find(k => k.startsWith("tag:"))
    if (key) {
      const values = highlightedFields[key]
      const tagName = key.substr(4,key.length - 4)
      return  values ? tagName + ' : ' + values[0] : ''
    } else return ''
  }
}

const getHighlightedText = (contentItem) => {
  const highlightedFields = contentItem?.highlightedFields
  if (!highlightedFields) return ''
  else {
    const values = highlightedFields["text"]
    return values ? values[0] : ''
  }
}

const getHighlightCount = (contentItem) => {
  const highlightedFields = contentItem?.highlightedFields
  if (!highlightedFields) return 0
  else {
    const keys = Object.keys(highlightedFields)
    const userLocaleNameKey = "name_" + userLocale.value
    const filteredKeys = keys.filter(k => !(k === userLocaleNameKey) || (k === "identifier" && !hideIdentifier.value))
    return filteredKeys.length
  }
}

const getHasNonLocalHighlight = (contentItem) => {
  const highlightedFields = contentItem?.highlightedFields
  if (!highlightedFields) return ''
  else {
    const keys = Object.keys(highlightedFields)
    const nameKeys = keys.filter(k => k.startsWith("name_") && !k.endsWith(userLocale.value))
    const descKeys = keys.filter(k => k.startsWith("description_") && !k.endsWith(userLocale.value))
    return ((nameKeys.length >= 1) || (descKeys.length >= 1))
  }
}

const getHighlightForThirdLine = (contentItem) => {
  const highlightCount = getHighlightCount(contentItem)
  if (highlightCount === 0) return ''
  if (highlightCount > 1) return '<br>Matches on ' + highlightCount + ' fields...'
  if ((contentItem.entityType?.toLowerCase() === 'page') ||
      (contentItem.entityType?.toLowerCase() === 'media' && contentItem.mediaType?.toLowerCase() === 'document')) {
    const text = getHighlightedText(contentItem)
    if (text !== '') {
      return '<br>Matches on text in document...'
    }
  }
  const highlightedTag = getHighlightedTag(contentItem)
  if (highlightedTag !== '') {
    return '<br>' + highlightedTag
  }
  const highlightedDescription = getHighlightedLocalDescription(contentItem)
  if (highlightedDescription !== '') {
    return '<br>' + highlightedDescription
  }
  if (getHasNonLocalHighlight(contentItem)) {
    return '<br>Matches on a translation in another locale...'
  }
  const highlightedIdentifier = getHighlightedIdentifier(contentItem)
  if (highlightedIdentifier !== '' && hideIdentifier.value) {
    return '<br>Matches on identifier...'
  } else return ''
}

const getThumbnailUrl = (contentItem) => {
  return contentItem?.thumbnailUrl ?? `https://static.digabit.com/${contentItem?.entityType?.toLowerCase()}.svg`
}
const selectContent = async (content, type) => {
  emit('selectContent', content, type, false)
}
const selectWhereUsed = async (content, type) => {
  emit('selectContent', content, type, true)
}
const searchContentType = (type) => {
  emit('searchContent', type)
}
</script>

<style scoped lang="scss">
.search-results-group {
  border-bottom: 2px solid lightgrey;
  padding-left: 1em;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.header-result-text {
  font-weight: bold;
}

.search-suggestions-header-title {
  padding: .25em;
  display: flex;
}

.search-suggestions-results-item {
  margin-top: .20em;
  margin-left: .25em;
  color: var(--primary);
  font-weight: bold;
}

.view-more-link {
  float: right;
  margin-left: auto;
  margin-right: 1em;
  white-space: nowrap;
}

.is-row {
  display: flex;
}

.content {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 0em !important;
}

.content-pointer {
  cursor: pointer;
}

.section-margin {
  margin-top: 1em;
}

.search-count-container {
  border: 1px solid lightgray;
  margin-right: .25em;
  margin-left: 1em;
  width: 3em;
  text-align: center;
  display: inline-block;;
}

.small-text {
  font-size: xx-small;
}

.identifier-text {
  font-style: oblique;
}

:deep(.highlightText) {
  text-decoration: underline;
}

.where-used-button-container {
  float: right;
  margin-left: auto;
  margin-right: 3.5em;
  @media (max-width: 1023px) {
    margin-right: 2em;
  }
}

.where-used-button {
  height: 1.5em;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6) !important;
  max-width: 8.5em;
  @media (max-width: 1023px) {
    width: 3em;
  }
  padding: .25em;
}
.where-used-button-text {
  font-size: small;
  margin-left: 0.25em;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 1023px) {
    visibility: hidden;
    width: 0;
  }
}
.where-used-button-count {
  font-size: small;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

</style>
