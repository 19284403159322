import http from '@/http'

export interface TenantPasswordResetEmail {
  body: String,
  subject: String
}

export async function loadTenantProperties(tk: string): Promise<any> {
  const { data } = await http.get(`tenants/${tk}/properties`)
  return data
}

export async function loadTenantPasswordResetEmail(tenantKey: String): Promise<TenantPasswordResetEmail> {
  const { data } = await http.get(`/admin/password-reset-email/${tenantKey}`)
  return data
}

export async function updateTenantPasswordResetEmail(tenantKey: String, dto: TenantPasswordResetEmail): Promise<TenantPasswordResetEmail> {
  const { data } = await http.put(`/admin/password-reset-email/${tenantKey}`, dto)
  return data
}

export async function deleteTenantPasswordResetEmail(tenantKey: String) {
  const { data } = await http.delete(`/admin/password-reset-email/${tenantKey}`)
  return data
}
