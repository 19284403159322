import http from '@/http';

export async function createTagValue({type, entityId, tagNameId, value}) {
  return http.post(`/${type}-tags/admin/by-entity/${entityId}/${tagNameId}`, value).then((res) => res.data);
}

export async function createTagsFromList({
  pendingCreate,
  entityId,
  type
}) {
  let createError = false
  let createSuccess = false

  for (let i = 0; i < pendingCreate.length; i++) {
    const element = pendingCreate[i]

    try {  
      await createTagValue({ 
        entityId, 
        tagNameId: element.tagNameId, 
        type, 
        value: element 
      })
    } catch {
      createError = true
    } finally {
      createSuccess = true
    }
  }

  return { createError, createSuccess }
}
