import http from '@/http';

export async function deleteTagValue({type, id}) {
  return http.delete(`/${type}-tags/admin/by-id/${id}`).then((res) => res.data);
}

export async function deleteTagsFromList({ pendingDelete, type }) {
  let deleteSuccess = false
  let deleteError = false

  for (let i = 0; i < pendingDelete.length; i++) {
    const tagId = pendingDelete[i]

    try {
      await deleteTagValue({ id: tagId, type })
    } catch {
      deleteError = true
    } finally {
      deleteSuccess = true
    }
  }

  return {
    deleteError,
    deleteSuccess
  }
}
