/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} из {b}',
  about: 'Сведения',
  aboutThisItem: 'Информация о продукте',
  accessApiKeyManager: 'Получить доступ к Диспетчеру ключа API для этого участника.',
  accessControls: 'Настройки Управления Доступом',
  accessControlsCopiedText: 'Настройки управления доступом автоматически обновлены. Авторизация медиабезопасности будет обновлена в течение нескольких минут.',
  accessControlsCopiedTitle: 'Настройки управления доступом успешно скопированы',
  accessControlsCopyConfirmation: 'Данная операция удалит все существующие настройки управления доступом из {to} и заменит их на настройки управления доступом из {from}. Данную операцию нельзя отменить.',
  accessControlsDescription: 'Настройки управления доступом определяют, кто может видеть медиа, основываясь на выбранных Организациях и Категориях Медиа.',
  accessControlsIndeterminance: 'Примечание: Вышестоящие организации всегда имеют доступ к контенту, присвоенному к любой их дочерней организации.',
  account: 'Учетная запись',
  accountErrorMessage: 'Сообщение об ошибке учетной записи',
  accountErrorMessageWarning: 'В поле accountErrorMessage базы данных для участника будет установлено значение «Несанкционированный».',
  action: 'Действие | Действия',
  actionDelete: 'удалить',
  actionLoad: 'загрузить',
  actionPaste: 'Вставить',
  actionSave: 'Сохранить',
  actionRun: 'запустить',
  actionUndoWarning: 'Данную операцию нельзя отменить.',
  actionUpload: 'загрузить',
  actualSize: 'Фактический Размер',
  add: 'Добавить',
  addAPIKey: 'Добавить Ключ API',
  addItem: 'Добавить {item}',
  addABookText: 'Добавить книгу',
  addASharedCart: 'Добавить совместную корзину',
  addATemplate: 'Добавить шаблон',
  addATag: 'Добавить тег',
  addABookToStart: '{addABookText}, чтобы начать.',
  addAForm: 'Добавьте Форму, чтобы начать',
  addAFormOnly: 'Добавить форму',
  addAMedia: 'Добавить медиафайл',
  addAnOrderQuoteEmailFormat: 'Добавить формат электронного сообщения о заказе/коммерческом предложении',
  addAPrintSettingText: 'Добавить Настройки Печати',
  addAPrintSettingToStart: '{addAPrintSettingText}, чтобы начать.',
  addBooks: 'Добавить Книги',
  addChildBrowseFlow: 'Добавить Просмотр Дочерних Потоков',
  addChapter: 'Добавить Раздел',
  addComment: 'Добавить комментарий',
  addExistingPart: 'Добавить Существующую Деталь',
  addGroupsToUsers: 'Добавить группы {groups} к пользователям {users}',
  additionalInformation: 'Дополнительная Информация',
  additionalNotification: 'Дополнительное Уведомление',
  additionalPartNumberDescription: 'Использовать как дополнительное поле номера детали',
  addBrowseFlow: 'Добавить Просмотр Потоков',
  addMedia: 'Добавить Медиа',
  addPages: 'Добавить Страницы',
  addPart: 'Добавить Деталь',
  addReports: 'Добавить Отчеты',
  addRootBrowseFlow: 'Добавить Просмотр Корневого Потока',
  addRow: 'Добавить строку',
  addTemplates: 'Добавить шаблоны',
  addToCartMessage: 'Успешно добавлено {partNumber}',
  addToUserGroup: 'Добавить в Группы',
  address: 'Адрес | Адреса',
  addressInUse: 'Адрес установлен как исходный и не может быть удален',
  addressInUseSubtitle: 'Чтобы удалить этот адрес, вам нужно сперва удалить его как адрес по умолчанию.',
  addressLine: 'Строка адреса {number}',
  addWidgetKey: 'Добавить ключ виджета',
  admin: 'Администратор',
  adminCenter: 'Центр Администратора',
adminCenterBanner: {
    bodyLabel: 'Тело баннера',
    enableLabel: 'Включить баннер администратора',
    headerGreenOption: 'Зеленый',
    headerLabel: 'Название баннера',
    headerRedOption: 'Красный',
    headerYellowOption: 'Желтый',
    title: 'Баннер центра администрирования',
    typeLabel: 'Тип баннера'
  },
  adminCenterMessage: 'Центр администратора для контента, пользователей, настроек библиотеки, отчетов и экспорта',
  adminMessage: 'Функции добавляются по мере их доступности',
  administration: 'Администрация',
  affectedBookMedia: 'Такое количество медиа‑книг будет обновлено: {count}.',
  affectedTemplateMedia: 'Такое количество шаблонов книг будет обновлено: {count}.',
  after: 'После',
  aggregatedFromChildren: 'Агрегировано из Дочерних',
  aiAlgorithm: 'Сканер на основе ИИ',
  all: 'Все',
  alphanumeric: 'Алфавитно-цифровой',
  algorithm: 'Алгоритм',
  alignHorizontal: 'Выравнивание По Горизонтали',
  alignVertical: 'Выравнивание По Вертикали',
  allUsersDisabled: 'Все пользователи, принадлежащие участнику, будут отключены.',
  analyticsJSON: 'JSON-файл аналитики',
  andCountMore: 'и еще {count}…',
  any: 'Любой',
  apiKey: 'Ключ API | Ключи API',
  apiKeyAdmin: 'Ключ API Администратора',
  apiAuthHeader: 'Заголовок Авторизации API',
  apiKeyCopied: 'Значение Заголовка Авторизации API скопировано в Буфер Обмена',
  apiKeyDeleted: 'Ключ API удален',
  apiKeyDeletedError: 'Ошибка удаления Ключа API',
  apiKeyDeleteMsg: 'Удаление этого Ключа API отключит его применение интеграциями',
  apiKeyDeleteTitle: 'Удалить этот Ключ API?',
  apiKeyInstructions: 'Ключ будет создан при Сохранении',
  apiKeyManager: 'Диспетчер Ключа API',
  apiKeyNameEmpty: 'Поле имени не должно быть пустым',
  apiKeyRegenError: 'Ошибка повторного создания Ключа API',
  apiKeyRegenMsg: 'Повторное создание Ключа API может привести к отключению существующих интеграций',
  apiKeyRegenTitle: 'Создать этот Ключ API повторно?',
  apiKeySaved: 'Ключ API Сохранен',
  apiKeyType: 'Тип ключа API',
  appliesTo: 'Применимо к',
  appliesToEmpty: 'Затребовать хотя бы один выбранный тип объекта',
  appliesToPopulated: 'Установить, к каким объектам может быть применен тег',
  applyItem: 'Применить {item}',
  applyToChildItems: 'Применить к дочернему {item}',
  applyToPartOnly: 'Применить только к этой детали',
  april: 'Апрель',
  area: 'Зона',
  areYouSure: 'Вы уверены, что хотите удалить {item}?',
  areYouSureCopy: 'Вы уверены, что хотите скопировать {item}?',
  areYouSureYouWantToDisableTheTenant: 'Действительно отключить участника?',
  assembly: 'Сборка | Сборки',
  assemblyCreatorSuccessTitle: 'Задание на создание сборки отправлено',
  assemblyCreatorSuccessSubtitle: 'Изменения в ссылках на основные точки и оглавлении книги будут применены немедленно. Результаты поиска будут отображены после завершения индексации.',
  assemblyConfirmationTitle: 'Вы хотите завершить создание сборки?',
  assemblyCreatorHelpText1: 'Эта функция позволяет вставить выбранную страницу в книги везде, где название {partName} этой детали будет найдено, автоматически соединяя деталь и выбранную страницу через ссылку-якорь. Вы можете опционально ограничить ссылку только выбранными книгами.',
  assemblyCreatorHelpText2: 'Выбранная страница будет вставлена единожды в каждую книгу после первого появления {partName}.',
  assemblyStepOne: 'Шаг 1: Выберите страницу, которую нужно вставить',
  assemblyStepTwo: 'Шаг 2: Выберите книги, в которые нужно вставить страницу',
  asset: 'Ресурс | Ресурсы',
  assetsNotFound: 'Тип {type} Не найден',
  assignBrowseFlow: 'Назначить Просмотр Потока',
  assignedOrganizations: 'Назначить Организации',
  atLeastFiveError: 'Выберите значение не меньше 5.',
  attachment: 'Вложение | Вложения',
  attachmentEditorDescription: 'Прикрепите изображения, видео и документы непосредственно к этому объекту. Примечание: их можно найти во вкладке «Похожее».',
  attachmentView: 'Просмотреть Вложенное',
  attachRelatedPDFs: 'Вставить PDF-файлы страниц соответствующих деталей',
  attributions: 'Атрибуты',
  august: 'Август',
  automaticZoom: 'Автоматическое Масштабирование',
  autoHotpoint: 'Автоматическая Центральная Точка',
  autoPopulate: 'Автоматическое Наполнение',
  availability: 'Доступность',
  back: 'Назад',
  backCover: 'Тыльная сторона Обложки',
  backStitch: 'Задний Шов',
  backToLibrary: 'Назад в Библиотеку',
  backToSearch: 'Вернуться к Поиску',
  badCredentials: 'Неверные Учетные данные',
  bahasaDescription: 'Описание На Индонезийском',
  bahasaName: 'Название На Индонезийском',
  banner: 'Баннер',
  before: 'До',
  beginTypingToFindAnything: 'Начните вводить для поиска',
  betaAccessKeys: 'Ключи доступа к бета-версии',
  betaResources: 'Бета-ресурсы',
  betaReleaseVideo: 'Видео Бета-версии',
  blobFinder: 'Система поиска BLOB',
  body: 'Текстовый блок',
  bomDefaultSupplier: 'Поставщик спецификации по умолчанию',
  bomItem: 'Номенклатура Спецификации',
  bom: 'Спецификация',
  bomExportSuccess: 'Экспорт спецификации выполнен успешно',
  bomLayout: 'Макет Перечня Материалов',
  bomListOptions: 'Опции Списка Спецификаций',
  bomListSettings: 'Настройки Списка Спецификаций',
  both: 'Оба',
  book: 'Книга | Книги',
  bookInserts: 'Содержание книги',
  bookLabel: 'Книга | Книги',
  bookPrintingDisable: 'Запретить Печать Книги',
  booksAndPagesDisplay: 'Отображение Книг и Страниц',
  boost: 'Ускорение Поиска',
  boostDescription: 'Добавить значение больше 1,5 для повышения актуальности поиска этого тега над номерами запчастей, идентификаторами медиа и именами',
  boostRangeInvalid: 'Показатель увеличения должен быть в диапазоне от 0,5 до 100,0',
  border: 'Граница',
  bottom: 'Низ',
  branding: 'Брендинг',
  browse: 'Просмотреть',
  browseComputer: 'Просмотр файлов на Компьютере',
  browseFlow: 'Галерея Просмотра| Галереи Просмотра',
  browseFlowAssigned: 'Просмотр Указанного Потока',
  browseFlowCopyMessage: 'Введите уникальное имя для Просмотра Потока.',
  browseFlowDeleted: 'Просмотреть удаленные потоки',
  browseFlowDeleteMessage: 'Удаление просмотра потока удаляет не только поток, но и все дочерние потоки.',
  browseFlowEmptyState: 'Просмотр Потока Не указан. {new}, чтобы начать.',
  browseFlowHeirarchy: 'Иерархия Просмотра Потока',
  browseFlowNoExistingCriteria: 'Критерии поиска не были указаны для этого просмотра потока.  Используйте элементы управление выше, чтобы выбрать критерии поиска',
  browseFlowMoveToRoot: 'Переместите узел сюда, чтобы установить дочернюю ветку первого уровня',
  browseFlowNoAssignedOrgs: 'Нет назначенных организаций',
  browseFlowSaveFailed: 'Просмотр Потока не удалось обновить',
  browseFlowSetDefaultSuccess: 'Установлен Просмотр Потока по умолчанию',
  browseFlowThumbnailDeleteFailTitle: 'Не удалось Удалить Миниатюру',
  browseFlowThumbnailDeleteSuccessTitle: 'Миниатюра Успешно Удалена',
  browseFlowThumbnailUploadFailTitle: 'Не удалось Загрузить Миниатюру',
  browseFlowThumbnailUploadSuccessTitle: 'Миниатюра Успешно Загружена',
  browseFlowTooManyOrgs: 'У вас слишком много организаций для использования текущего редактора галереи просмотра. Пожалуйста, свяжитесь с Центром поддержки Documoto по адресу {email}, чтобы получить помощь с редактированием галереи просмотра Documoto.',
  browserPartiallySupport: 'В этом браузере вы можете заметить отличия эстетического характера и другие изменения.',
  browseFlowSaved: 'Просмотр Потока сохранен',
  browserDeprecation: {
    title: 'Уведомление об Устаревшем Браузере',
    text: 'В свете того, что Microsoft перестает поддерживать обновление браузера Internet Explorer (все версии), а также из-за других соображений безопасности мы не будем в дальнейшем поддерживать доступ пользователей через Internet Explorer (все версии) после 30 сентября 2021 года.'
  },
  buildHotpointLinks: 'Создание Ссылок-якорей',
  buildHotpointLinksInfo: 'Это действие сначала удалит все существующие ссылки-якоря из страниц книг, а потом создаст новые ссылки-якоря между деталями и подходящими страницами сборки.',
  buildHotpointLinksTitle: 'Вы хотите создать ссылки-якоря для этой книги?',
  bulkIndexingLane: 'Дорожка пакетного индексирования',
  bulkInsert: 'Массовая Вставка',
  bulkExportConfirm: 'Вы хотите экспортировать {n} {unit}?',
  bulkExportLimit: 'Лимит составляет {limit} книг(и) на один экспорт',
  bulkPageInsertJob: 'Объемная Вставка Страниц',
  business: 'Бизнес',
  cancel: 'Отменить',
  canceled: 'Отменено',
  cancelAssemblyCreatorTitle: 'Удалить задание по созданию сборки?',
  cancelAssemblyCreatorSubtitle: 'Задание по созданию сборки не будет обработано.',
  cannotExceed100: 'Не может превышать 100 %',
  cart: 'Корзина | Корзины',
  cartBehaviorEnabled: 'Разрешить Подсказку о Количестве Добавленного в Корзину',
  cartBehaviorDisabled: 'Запретить Подсказку о Количестве Добавленного в Корзину',
  cartBehaviorLabel: 'Поведение для Количества Добавленного в Корзину',
  cartColumn: 'Колонка Корзины | Колонки Корзины',
  cartColumn1: '1-я Колонка Цены Корзины',
  cartColumn2: '2-я Колонка Цены Корзины',
  cartColumn3: '3-я Колонка Цены Корзины',
  cartColumnPartLocationContent1: 'Включение этой функции будет иметь следующие последствия:',
  cartColumnPartLocationContent2: 'Уникальная строка детали в корзине также будет основываться на ее местоположении в контенте, если такой способ можно применить.',
  cartColumnPartLocationContent3: 'Эти настройки нельзя отключить на уровне организации, и они будут применены ко всем пользователям.',
  cartColumnPartLocationContent4: 'Эти настройки не стоит включать, если у вас есть интеграция Отправить Корзину. Для этого необходимо связаться с вашим Менеджером по Сопровождению Клиентов Documoto.',
  cartColumnPartLocationOption: 'Включить Данные Местоположения Детали в Корзине',
  cartColumnPartLocationTitle: 'Вы уверены, что хотите включить Данные Местоположения Детали в Корзине?',
  cartDisable: 'Отключить Корзину ',
  cartEmailFailed: 'Ошибка сообщения электронной почты корзины',
  cartEmailSent: 'Сообщение электронной почты корзины отправлено',
  cartEnable: 'Включить Корзину ',
  cartSaveEnable: 'Включить Сохранение Корзины',
  cartErpMessage: 'Успешно отправлено в ERP',
  cartImportError: 'Ошибка при импорте корзины',
  cartLoadedWithItemsRemoved: '{count} ед. номенклатуры удалена (-но), потому что в данный момент у вас нет разрешения на ее (их) заказ',
  cartSharedAddEdit: 'Добавить / Редактировать Совместные Корзины',
  cartUpdated: 'Корзина Обновлена',
  cartUpdatedAndRemovedCount: 'Корзина Обновлена; удалено {count} деталей, которые нельзя заказать',
  category: 'Категория | Категории',
  categoryCreated: 'Категория Создана',
  categoryUpdated: 'Категория Обновлена',
  centimeter: 'Сантиметр| Сантиметры',
  changeNotification: 'Уведомление Об Изменении',
  changeScope: '{n} {type} будет затронут',
  changeUserOrganization: 'Изменить Организацию',
  chapter: 'Глава | Главы',
  chapterHeader: 'Заголовок Главы',
  chaptersOnly: 'Только Главы',
  chineseDescription: 'Описание На Китайском',
  chineseName: 'Название На Китайском',
  circle: 'Круг',
  city: 'Город',
  classicAdminMessage: 'Вы будете направлены обратно в Flex, чтобы воспользоваться модулями администратора.',
  classicAdmins: 'Классические Администраторы',
  classicExporter: 'Классический Экспортер',
  classicExporterMessage: 'Модуль прежней версии на базе Flash для экспорта контента',
  classicOrganizationAdmin: 'Классический Администратор Организации',
  classicOrganizationAdminMessage: 'Модуль управления пользователями прежней версии на базе Flash для управления организациями и пользователями',
  classicPublisher: 'Классический Публикатор',
  classicPublisherMessage: 'Модуль публикации прежней версии на базе Flash для создания контента и настроек печати',
  classicReporting: 'Классический Отчет',
  classicReportingMessage: 'Модуль прежней версии на базе Flash для отчетов',
  classicTenantAdmin: 'Классический Администратор Клиента',
  classicTenantAdminMessage: 'Административный модуль прежней версии на базе Flash для клиентских настроек',
  className: 'Название класса',
  clear: 'Очистить',
  clearItemNumber: 'Удалить номер товара',
  clearItemNumberTitle: 'Удалить номер товара?',
  clearItemNumberMessage: 'Удаление номера товара приведет к удалению следующих данных:',
  clearFilters: 'Очистить Фильтры',
  clearHotpoints: 'Очистить основные точки',
  clearSearch: 'Очистить Историю Поиска',
  clickHereToUpload: 'Перенесите файл или нажмите, чтобы загрузить перезапись.',
  clickMagnifyingGlass: 'Нажмите увеличительное стекло, чтобы перейти к расширенному поиску.',
  clickOkToContinue: 'Нажмите OK, чтобы продолжить.',
  clickToDownload: 'Нажмите для Загрузки',
  clickToEnlarge: 'Нажмите, чтобы увеличить',
  clickToOpen: 'Нажмите, чтобы Открыть',
  clipboard: 'Буфер Для Обмена Данными',
  clipboardAccessError: 'Измените настройки браузера, чтобы разрешить доступ к Буферу Обмена Данными',
  close: 'Закрыть',
  closed: 'Закрыто',
  collapseAll: 'Свернуть Все',
  color: 'Цвет | Цвета',
  column: 'Колонка | Столбцы',
  columnName: 'Название Колонки',
  columnItemType: {
    customText: 'Индивидуальный Текст',
    documentTitle: 'Название Документа',
    horizontalLine: 'Горизонтальная Линия',
    pageName: 'Название Страницы',
    pageNumber: 'Номер Страницы',
    printDate: 'Дата Печати',
    tenantName: 'Имя Участника',
    userName: 'Имя Пользователя'
  },
  combinedPage: 'Комбинированная Страница',
  combinedPageMessage: 'Диаграмма сверху, список деталей снизу',
  comingSoon: 'Ожидается',
  commaDelimited: 'с разделителями-запятыми',
  comment: 'Комментарий | Комментарии',
  commentAdded: 'Комментарий добавлен',
  commentDeleted: 'Комментарий удален',
  commentEdit: 'Добавить / Редактировать Комментарий',
  commentEditAll: 'Редактировать Все Комментарии',
  commentUpdated: 'Комментарий обновлен',
  commentView: 'Посмотреть Комментарии',
  commerceSystem: 'Торговая Система',
  completed: 'Завершено',
  confirm: 'Подтвердить',
  confirmBrowseFlowApplyToChildren: 'Вы хотите применить этот просмотр потока к дочерней организации?',
  confirmBrowseFlowOverrideToChildren: 'Данная операция удалит все настройки просмотра потока для дочерних организаций.',
  confirmPrintSettingsApplyToChildren: 'Вы хотите применить эти настройки печати к дочерней организации?',
  confirmPrintSettingsOverrideToChildren: 'Данная операция удалит все настройки печати для дочерних организаций.',
  contains: 'Содержит',
  containsHeaderRow: 'Содержит Строку Заголовка',
  content: 'Контент| Контент',
  contentAccessControls: 'Средства Управления Доступом к {content}',
  contentEntity: 'Элемент Контента | Элементы Контента',
  contentNotFound: 'Содержимое не найдено',
  contentUpdatedQueueJob: 'Очередь Обновления Содержимого',
  contourShape: 'Форма Контура',
  convertEntity: 'Конвертировать {entity}',
  convertToEntity: 'Конвертировать в {entity}',
  copyAccessControls: 'Скопировать Настройки Управления Доступом',
  copyAccessControlsWarning: 'Это скопирует все установленные настройки управления доступом из выбранной Организации в текущую Организацию',
  copy: 'Скопировать',
  copyAndRetain: 'Скопировать и Сохранить для Использования Интеграции',
  copyAndRetainWidget: 'Значение авторизации виджета будет отображаться в приложении только при выполнении функции «Скопировать и сохранить» (Copy & Retain)',
  copyAtoB: 'Скопировать {a} в {b}',
  copyContent: 'Копировать {type}? {name}',
  copyingItem: 'Копирование {item}',
  copyItem: 'Скопировать {item}',
  copyItemQuestion: 'Копировать {item}?',
  copyTo: 'Скопировать в {target}',
  copyMeMessage: 'Поставить меня в копию к этому сообщению',
  copyrightMessage: '© 2010–{year} Documoto Inc. Все права защищены.',
  count: 'Счет',
  country: 'Страна | Страны',
  coverPages: 'Титульные Страницы',
  coverAmpStitchPages: 'Сшитые и Титульные Страницы',
  createAssembly: 'Создать Сборку',
  created: 'Создано',
  createdBy: 'Создано {name}',
  createdDate: 'Дата создания',
  createdOn: 'Создано',
  currentPassword: 'Текущий Пароль',
  custom: 'Пользовательский',
  czechDescription: 'Описание На Чешском',
  czechName: 'Название На Чешском',
  dashboard: 'Панель инструментов | Панели инструментов',
  dashboardLibrary: 'Библиотека Панели Управления',
  dataInsights: 'Представление Данных',
  dataInsightsInfoMessage: 'Центр Отчетов и Панели Управления для представления данных о содержимом, пользовательской информации и библиотечных данных.',
  dataMapping: 'Сопоставление Данных / Образцы Данных (до 10 строк)',
  dataOptions: 'Параметры данных',
  date: 'Дата',
  dateModified: 'Дата Обновления',
  debugPageBuilderPLZ: 'Отладка PLZ Конструктора страниц',
  december: 'Декабрь',
  decreaseHotpoint: 'Снижение Центральной Точки',
  default: 'По умолчанию',
  defaultAddress: 'Адрес по умолчанию',
  defaultBrowseFlow: 'Просмотр Потока по умолчанию',
  defaultCurrencyCode: 'Валютный Код по умолчанию',
  defaultCurrencySymbolOn: 'Символ валюты по умолчанию вкл.',
  defaultEmailRecipient: 'Получатель Электронного Письма по умолчанию | Получатели Электронного Письма по умолчанию',
  defaultImage: 'Изображение по умолчанию | Изображения по умолчанию',
  defaultImagesManager: 'Менеджер Изображений по умолчанию',
  defaultItemImage: 'Изображение {item} по умолчанию',
  defaultPartsListLayout: 'Раскладка Списка Деталей по умолчанию',
  defaultPartsListLayoutHelp: 'Раскладка деталей по умолчанию слева или под изображением',
  defaultSearchMode: 'Режим Поиска по умолчанию',
  defaultSearchModeHelp: 'Метод глобального поиска по умолчанию для \'содержит\' или \'точно\'',
  defaultSortMode: 'Режим Сортировки по умолчанию',
  defaultSupplier: 'Поставщик По Умолчанию',
  defaultTocStateOpen: 'Отрытое Содержание по умолчанию',
  defaultTocStateOpenHelp: 'Определяет, должно ли содержание быть автоматически открыто при просмотре книги',
  defaultUom: 'ЕИ По Умолчанию',
  defaultValue: 'Значение По умолчанию',
  defaults: 'По умолчанию',
  defaultsAndCapabilities: 'Настройки по умолчанию и Дополнительные Возможности',
  delete: 'Удалить',
  deleteAll: 'Унаследовать От Родителя',
  deleteAnyPartnerApiKeys: 'Удалить все ключи API партнеров, которые были предоставлены SmartEquip.',
  deleteContent: 'Вы уверены, что хотите удалить {item}? {name}',
deletePart: {
    infoOne: 'Функция удаления частей начнет действовать немедленно в Библиотеке и контенте администратора. Удаленная часть будет доступна для поиска, пока не произойдет переиндексация в течение нескольких минут.',
    deletingPartNumber: 'Будет удалено: {partNumber}.',
    affectedCarts: 'Эта часть будет полностью удалена со всех корзин, в которых она содержится.',
    infoTwo: 'Это действие невозможно отменить без помощи специалистов Службы поддержки Documoto. По истечении 90 дней удаленная запись и все ассоциации будут полностью стерты.'
  },
deletePartInUse: {
    title: 'Эта часть содержится на странице (страницах) и не может быть удалена.',
    body: 'На платформе Documoto в данный момент поддерживается лишь функция удаления частей, которые не содержатся на страницах. Чтобы продолжить этот процесс, удалите данную часть со всех опубликованных или черновых страниц.',
    submitLabel: 'Перейти к страницам'
  },
  deleteRelated: 'Действительно удалить {count} {type}?',
  deletedItem: 'Удалено {item}',
  deleteFilesMessage: 'Вы действительно хотите удалить {count} файл(а/ов)?',
  deleteHotpointLinksTitle: 'Вы действительно хотите удалить ссылку (-и) hotpoint?',
  deleteMessage: 'Действительно удалить?',
  deleteSelectedCriteria: 'Удалить Выбранные Критерии',
  deleteTagsTitle: 'Вы действительно хотите удалить тег (-и)?',
  deleteTaxonMessage: 'Это действие навсегда удалит этот таксон вместе со всеми его дочерними элементами. Вы уверены, что хотите продолжить?',
  deleteTaxonomyMessage: 'Это действие навсегда удалит все таксоны для этой Организации или Участника. Вы уверены, что хотите продолжить?',
  deleteTenantSupplier: 'Действительно удалить этого поставщика для участника?',
  demo: 'Демонстрация',
  description: 'Описание',
  deselectAll: 'Снять Все Выделение',
  deselectCriteria: 'Отменить Выбор Критериев',
  detail: 'Сведения',
  diagonal: 'Диагональ',
  diameter: 'Диаметр',
  disable: 'Отключить',
  disableAll: 'Отключить все',
  disableDocumotoTenant: 'Отключить участника Documoto',
  disableFwdReindex: 'Отключить пересылку целевых медиафайлов реиндексирования для DSW',
  disableLibraryBannerImage: 'Отключить изображение баннера библиотеки',
  disableSmartEquipModalTitle: 'Действительно отключить эту лицензию?',
  disableTEK: 'Отключить Вход Напрямую Через URL',
  disabled: 'Отключенный',
  discard: 'Отменить',
  discardChanges: 'Отменить изменения',
  discardQ: 'Отменить изменения?',
  discounted: 'Со скидкой',
  discountedPrice: 'Цена Со Скидкой',
  discountedTotal: 'Сумма С Учетом Скидки',
  discountPrice: 'Цена Со Скидкой',
  displayInOrdersList: 'Отобразить в Списке Заказов',
  displayOrder: 'Порядок Отображения',
  displayOrderDescription: 'Устанавливает порядок отображения Фильтров в выпадающем окне для тегов типа контента',
  document: 'Документ | Документы',
  documentLabel: 'Документ | Документы',
  documentSettings: 'Настройки документа',
  documotoAcademy: 'Академия Documoto',
  documotoLicensePackage: 'Лицензионный пакет Documoto',
  domain: 'Домен',
  domainEmptyMessage: 'Поле с разрешенным доменом не может быть пустым',
  domainInvalidMessage: 'Необходимо указать действительное имя домена',
  download: 'Загрузить',
  downloadBrowser: 'Загрузите один из этих удобных браузеров.',
  downloadFailed: 'Ошибка при загрузке {name}',
  downloadStarted: 'Выполняется загрузка {name}',
  draft: 'Черновик | Черновики',
  draftBomDeleteTitle: 'Вы действительно хотите удалить эту деталь со страницы?',
  draftBomMultipleDeleteTitle: 'Вы действительно хотите удалить эти детали со страницы?',
  draftBomDropError: 'Невозможно разместить детали глубже 5-го уровня',
  draftBomEmptyField: 'Все поля в списке "Детали" должны быть заполнены',
  draftBomQuantityError: 'Значение должно быть целым числом',
  draftBomError: 'Ошибка при работе с черновиком Спецификации',
  draftPage: 'Черновик Страницы',
  draftPageDelete: 'Удалить Черновик',
  draftPageDeleteMessage: 'Черновик страницы успешно удален.',
  draftPageDeleteWarning: 'Удаление черновика Страницы приведет к удалению текущих обновлений.',
  draftPageLastUpdated: 'Черновик. Последнее Обновление: {date} {username}',
  draftPageLoadError: 'Не удалось загрузить страницу проекта.',
  draftPageProcessingPlz: 'Страница загружается из PLZ-файла',
  draftPagePublishInProgress: 'Текущая Страница: "Публикация"',
  draftPageSaveSuccess: 'Черновик страницы успешно сохранен.',
  draftPageSaveUnsuccessful: 'Не удалось сохранить черновик страницы.',
  draftSheetImageHotpointsFailure: 'Не удалось сгенерировать основные точки {algorithm} для чернового изображения.',
  draftValidationMessage: {
    item: 'ПРЕДУПРЕЖДЕНИЕ: Для BOM с артикульным номером {partNumber} не указан номер товара',
    empty: 'Отсутствие Ошибок/Предупреждений Во Время Проверки',
    hotpointOrHotpointLink: 'У элемента спецификации {item} отсутствует центральная (-ые) точка (-и) / ссылка (-и) центральной (-ых) точки (-ек)',
    quantity: 'Для элемента спецификации {item} не указано количество',
    tag: 'У тега {tagName} для элемента спецификации {item} отсутствует значение тега',
    translationDescription: 'Для элемента спецификации {item} отсутствует перевод описания на {lang}',
    uom: 'Для элемента спецификации {item} не указана единица измерения',
    supersede: 'Запчасть {partNumber} замещена запчастью {supersededPartNumber} в связи с заменой.'
  },
  drawAutohotpointRegion: 'Нарисовать {shape} размер',
  drawMinimumArea: 'Нарисовать минимальную область',
  dropFileMessage: 'Перенесите файл сюда или нажмите для загрузки.',
  duplicate: 'Дублировать',
  duplicateSaveError: 'Дублирующая запись. Невозможно сохранить.',
  dutchDescription: 'Описание На Нидерландском',
  dutchName: 'Название На Нидерландском',
  dynamic: 'Динамический',
  dynamicNaming: 'Динамическое Присвоение Имен',
  ecommerce: 'Электронная коммерция',
  ecommerceEmailFormatCreated: 'Формат электронного сообщения онлайн-коммерции создан',
  ecommerceEmailFormatSaved: 'Формат электронного сообщения онлайн-коммерции сохранен',
  ecommerceLogo: 'Логотип электронной коммерции',
  ecommerceUseExtOrder: 'Электронный магазин использует внешний номер заказа',
  edit: 'Редактировать',
  editItem: 'Редактировать {item}',
  email: 'Электронная почта',
  emailFormat: 'Формат электронного сообщения',
  emailFormatTemplate: 'Шаблон формата электронного сообщения | Шаблоны форматов электронных сообщений',
  emailList: 'Список Электронных сообщений',
  emailMessage: 'Необходим адрес электронной почты',
  emailRecipient: 'Получатель электронного письма| Получатели электронного письма',
  emailRecipientMessage: 'Письмо будет отправлено на данную электронную почту, когда статус заказ измениться на',
  emptyMessage: {
    orderSuggestions: 'Похоже, что пока нет Рекомендаций к Заказу для этой детали.'
  },
  emptyBomState: 'Номенклатура Спецификации для этой страницы не определена. Нажмите "+",{add}чтобы начать',
  enable: 'Включить',
  enable3d: 'Включить 3D',
  enableAll: 'Включить все',
  enableAPIKeyAdmin: 'Включить ключ API администратора',
  enabled: 'Включенный',
  enableCommerce: 'Включить Торговлю',
  enableEcommerceOrderManagement: 'Включить управление заказом',
  enableExporter: 'Включить экспортер',
  enableExportToLinkOne: 'Включить экспорт в LinkOne',
  enableExportToSmartEquip: 'Включить экспорт в SmartEquip',
  enablePDFDownloadWarning: 'Включить предупреждение о загрузке больших PDF-файлов',
  largePDFDownloadWarning: 'Из-за большого размера загружаемого PDF-файла может потребоваться больше времени для отображения документа в приложении. Дождитесь, пока загрузится файл.',
  enableIframeSupport: 'Включить поддержку iFrame',
  enableLibraryBetaFeatures: 'Включить бета‑функции Библиотеки',
  enablePerformanceLogging: 'Включить журналирование производительности',
  enablePLZToDraft: 'Включить обработку PLZ с созданием черновика',
  enableQuickAddToCart: 'Включить быстрое добавление в корзину',
  enableQuickAddToCartHelpContext: 'Если выбран вариант \«Включить\», над областью просмотра содержимого появится функция быстрого добавления в корзину.',
  enableRocketUi: 'Включить взаимодействие клиента «Rocket User Experience»',
  enableSmartEquipApiIntegration: 'Включить интеграцию API SmartEquip',
  enableWidgetLogo: 'Включить логотип виджета Documoto',
  enableWidgets: 'Включить виджеты',
  encryptedKey: 'Зашифрованный Ключ',
  endDate: 'Дата завершения',
  endDateBad: 'Дата начала должна совпадать с датой окончания или предшествовать ей.',
  enforceNumericSize: 'Применить Числовой Размер',
  englishDescription: 'Описание На Английском',
  englishName: 'Название На Английском',
  enterprise: 'Предприятие',
  enterReportParameters: 'Введите Параметры Отчета',
  enterUniqueNameForNewItem: 'Введите уникальное имя для нового {item}',
  enterValidNumber: 'Пожалуйста, введите действительное число',
  enterValidEmail: 'Пожалуйста, введите действительный адрес электронной почты',
  entityType: 'Тип элемента | Типы элементов',
  erp: 'ERP',
  erpEndpointConfig: 'Конфигурация конечной точки ERP',
  erpPartInfo: 'Продолжительность кэширования информации компонента ERP, минуты',
  erpSystem: 'Система ERP',
  erpSystems: 'ERP‑системы',
  erpSystemSaved: 'ERP‑система сохранена',
  error: 'Ошибка',
  errorDeletePageMessage: 'При удалении этой страницы возникла проблема.',
  errorInputParsing: 'Проблема с разбором входного файла.',
  errorPageMessage: 'Произошла ошибка.',
  errorPageTitle: 'Произошла ошибка…',
  errorType: {
    error: 'Ошибка',
    warning: 'Внимание'
  },
  essential: 'Основное | Основы',
  eta: 'Расчетное время прибытия',
  etlJob: 'Извлечение, Преобразование И Загрузка',
  exact: 'Точно',
  excelFile: 'Файл Excel',
  expandAll: 'Развернуть Все',
  export: 'Экспортировать',
  exportSearch: 'Экспортировать Поиск',
  exportAsCsv: 'Экспортировать в формате CSV (Необходимый Формат для Импорта)',
  exportAsCSVDropdown: 'Экспортировать в формате CSV',
  exportAsHtml: 'Экспорт в формате HTML',
  exportAsPdf: 'Экспортировать в формате PDF',
  exportAsPlz: 'Экспортировать в формате PLZ',
  exportAsXlsx: 'Экспортировать в формате XLSX',
  exportBasicPartInfo: 'Экспортировать Информацию про Основную Деталь',
  exportCartToXlsxDisabled: 'Отключить Экспорт Корзины в формате XLSX',
  exportCartToXlsxEnabled: 'Включить Экспорт Корзины в формате XLSX',
  exportDisplayedCartColumns: 'Экспортировать Колонки, которые Отображаются в Корзине',
  exportJob: 'Операция по экспорту',
  exportLane: 'Дорожка экспорта',
  exportMessage: 'Успешно отправленная операция по экспорту.',
  exporter: 'Экспортер',
  exporterMessage: 'Позволяет экспортировать контент',
  exportParts: 'Экспортировать Детали',
  external: 'Внешний ресурс | Внешние ресурсы',
  externalUrl: 'Внешний URL',
  faceted: 'Фасетный',
  facetedTag: 'Значения поиска должны быть многофункциональными',
  facetedTagTooMany: 'Тег превышает лимит 1000 уникальных значений тега',
  failedAction: '{content} не удалось {action}.',
  failedProcessContentSql: 'Не удалось обработать Информацию по причине неавторизованного текста. Повторно загрузите поддерживаемую информацию.',
  failedToCopy: 'Не удалось скопировать {content}.',
  failedToDelete: 'Не удалось удалить {content}.',
  failedToLoad: 'Не удалось загрузить {content}.',
  failedToSave: 'Не удалось сохранить {content}.',
  failedToSubmitItem: 'Не удалось отправить {item}',
  failedToUpdate: 'Не удалось обновить {content}.',
  false: 'Нет',
  faq: 'Вопросы и ответы',
  favorites: 'Избранное',
  favoritesLists: 'Список Избранного',
  february: 'Февраль',
  feedbackForm: 'Оставить Отзыв',
  feedbackFormMessage: 'Расскажите о функциях, которые вы хотели бы увидеть',
  fieldDefinition: 'Определение Поля',
  field: 'Поле | Поля',
  fieldName: 'Название поля',
  file: 'Файл | Файлы',
  filename: 'Имя файла',
  filesSubmitted: 'Файлы переданы на обработку',
  fileUpload: 'Отправка файла',
  fill: 'Заполнить',
  filter: 'Фильтр | Фильтры',
  filterOrgNameOnly: 'Фильтр: Только Имя Организации',
  filterOrgParentNames: 'Фильтр: Названия Организации и Родителя',
  filterable: 'Фильтруемый',
  filterableTag: 'Тег, по которому можно фильтровать',
  filterBy: 'Фильтровать по…',
  filterBoundsError: 'Фильтр должен содержать 3 или более символов',
  finnishDescription: 'Описание На Финском',
  finnishName: 'Название На Финском',
  firstName: 'Имя',
  firstNameEmpty: 'Имя должно быть заполнено',
  fixedHotpointSize: 'Фиксированный Размер Ссылки-якоря',
  font: 'Шрифт| Шрифты',
  footer: 'Нижний колонтитул',
  forgotPassword: 'Не могу вспомнить Пароль',
  forgotPasswordMessage: 'Письмо с инструкциями по смене пароля выслано на адрес указанного пользователя.',
  forgotPasswordSuccess: 'Если указанное имя пользователя действительно, на сохраненный адрес будет отправлено письмо с инструкциями по смене пароля.',
  form: 'Форма | Формы',
  format: 'Формат',
  formattingOptions: 'Параметры форматирования',
  formCount: 'Количество Форм',
  formName: 'Имя формы',
  formType: 'Тип формы',
  freezeImage: 'Зафиксировать изображение',
  frenchDescription: 'Описание На Французском',
  frenchName: 'Название На Французском',
  fri: 'Пт',
  from: 'От',
  fromDate: 'Начальная Дата',
  frontCover: 'Обложка',
  frontStitch: 'Передний Шов',
  fullScreen: 'Полноэкранный',
  gdprFaqs: 'Вопросы и ответы по Общим положениям о защите данных',
  gdprMessage: 'Выполняя вход в этот раздел нашего сайта, вы подтверждаете, что вам известно об использовании нами необходимых файлов cookie. Эти файлы cookie необходимы для обеспечения навигации в наших Услугах. Без данных файлов cookie мы не сможем предоставить вам запрошенные услуги. Для получения дополнительной информации ознакомьтесь с нашей {policy}.',
  general: 'Общее',
  generalSearch: 'Общий Поиск',
  generic: 'Исходный',
  germanDescription: 'Описание На Немецком',
  germanName: 'Название На Немецком',
  gotIt: 'OK',
  goTo: 'Перейти',
  goToHome: 'Перейти на Главную страницу',
  goToJobManager: 'Перейти к Диспетчеру Операций',
  goToSearch: 'Перейти к Поиску',
  hashKey: 'Хеш-ключ',
  headerAmpFooter: 'Верхнее и Нижнее Меню',
  header: 'Заглавие',
  help: 'Справка',
  helpCenter: 'Центр Поддержки',
  highlightDescription: 'Выделите детали в списке деталей с помощью этого тега',
  history: 'История',
  home: 'Главный экран',
  horizontal: 'Горизонтальный',
  host: 'Хост',
  hotpoint: 'Ссылка-якорь | Ссылки-якоря',
  hotpointCalloutBehavior: 'Режим выноски центральной точки',
  hotpointLinksWillBeCreated: 'Ссылки-якоря будут созданы в {count} {content}',
  hotpointLink: 'Ссылка Hotpoint | Ссылки Hotpoint',
  hotpointsGenerated: 'Количество найденных на изображении горячих точек составляет {count}.',
  hotpointShift: 'Центральная Точка (Shift + H)',
  hourlyDWSRequestLimit: 'Ограничение на число запросов DWS в час',
  hourlyRESTRequestLimit: 'Ограничение на число запросов REST в час',
  htmlUiUserSwitchEnabled: 'Переключатель пользователя интерфейса HTML включен',
  hungarianDescription: 'Описание На Венгерском',
  hungarianName: 'Название На Венгерском',
  icon: 'Значок',
  id: 'Идентификатор',
  identifier: 'Идентификатор',
  ignore: 'ИГНОРИРОВАТЬ',
  ignoreAccessControls: 'Игнорировать Настройки Управления Доступом',
  ignoreMatchingTranslations: 'Игнорировать переводы с соответствующими локалями при импорте',
  illustration: 'Иллюстрация | Иллюстрации',
  image: 'Изображение | Изображения',
  imageDeleteMessage: 'Вы действительно хотите удалить это Изображение Страницы?',
  imageUploadFailure: 'Не Удалось Загрузить Изображение',
  imageSaveFail: 'Не Удалось Сохранить Изображение',
  imageOptimizer: 'Оптимизатор Изображений',
  imageSizeExceededTitle: 'Превышен Максимальный Размер Изображения',
  imageSizeExceededMessage: 'Максимальный размер файла изображения, допустимый в Documoto, составляет {size} MB. Изображение, которое вы пытаетесь загрузить, превышает максимальный размер и не может быть загружено. Измените размер изображения, чтобы добавить его на эту страницу.',
  imageDimensionsExceededMessage: 'Максимальный размер изображения в Documoto составляет 65,5 мегапикселя (8191 × 8191 пиксель).  Изображение, которое вы пытаетесь загрузить, превышает максимальный размер и не может быть загружено. Нажмите "Изменить размер", чтобы разрешить Documoto изменить размер и вставить изображение, или выберите "Отмена", чтобы отредактировать изображение и вставить его вручную.',
  import: 'Импортировать',
  importBOM: 'Импортировать Список Спецификаций',
  importBOMError: 'Не удалось обработать образцы данных. Перепроверьте источник входных данных',
  importParts: 'Импортировать Детали',
  inCart: 'В Корзине',
  inCartDescription: 'Отображать теги в корзине',
  includePagePartDetails: 'Включить сведения страницы на уровне запчасти',
  includePartDetails: 'Включить сведения на уровне запчасти',
  indexQueueJob: 'Очередь индексирования',
  inPartsList: 'В Списке Деталей',
  inPartsListDescription: 'Отображать теги в списке деталей',
  inputSource: 'Источник Входных Данных',
  inch: 'Дюйм| Дюймы',
  includeComments: 'Учитывать Комментарии',
  includeCommentsAuthorAndDate: 'Учитывать Комментарий Автора и Дату',
  includeHeaderRow: 'Включить строку заголовка',
  includeImages: 'Учитывать Изображения',
  includePagesIndex: 'Учитывать Индекс Страниц',
  includePartsIndex: 'Учитывать Индекс Деталей',
  includePartsList: 'Учитывать Список Деталей',
  includeSubChapters: 'Включить Подразделы',
  indexLane: 'Дорожка индекса',
  useSequentialPageNumbering: 'Использовать Последовательную Нумерацию Страниц',
  hideBookInSearchAndRecent: 'Не показывать Книги в Результатах Поиска и в Недавно Добавленном',
  hideDuplicatePages: 'Не показывать Страницы-дубликаты',
  hidePageInSearchAndRecent: 'Не показывать Страницы в Результатах Поиска и в Недавно Добавленном',
  hidePartInSearchAndRecent: 'Не показывать Детали в Результатах Поиска и в Недавно Добавленном',
  homePageSettings: 'Настройки домашней страницы',
  hotpointZoomBehavior: 'Режим масштабирования центральной точки',
  includeNewParts: 'Включать только «новые» запчасти (которых нет в Documoto)',
  includeWatermark: 'Включить водяной знак?',
  increaseHotpoint: 'Увеличение Центральной Точки',
  indexed: 'Проиндексировано',
  indexingConfiguration: 'Конфигурация индексирования',
  indexIncludeTimestamp: 'Индекс включает метку времени',
  info: 'Информация',
  information: 'Информация',
  inherit: 'Наследовать',
  inheritedParent: 'Унаследовано от Родителя.',
  inputSpecification: 'Параметры Входных Данных',
  inputType: 'Тип Входных Данных',
  inputTypeListboxWarning: 'Конфигурации сортировки будут отображены после сохранения',
  integration: 'Интеграция | Интеграции',
  integrationsAddPartToErpCart: '{action} Добавить Деталь в Корзину ERP',
  integrationsAddToShoppingCartPricingFromErp: '{action} Добавить к Определению Стоимости Корзины из ERP',
  integrationsDisableErp: 'Отключить ERP',
  integrationsDoErpForQuotes: 'Провести ERP для Расценки',
  integrationsEnableErp: 'Включить ERP',
  integrationsEnableFetchPartInfoFromErp: 'Включить Запрос Информации о Детали из ERP',
  integrationsEnableScopeErp: 'Включить {scope} ERP',
  integrationsEnableSendShoppingCartToErp: 'Включить Отправку Корзины в ERP',
  integrationsEnableSyncPoAddressWithErp: 'Включить Синхронизацию Почтового Адреса с ERP',
  integrationsErpAvailabilityMappedFrom: 'Доступность ERP, спланированная из',
  integrationsErpCurrencyCodeMappedFrom: 'Код Валюты ERP, Спланированный Из',
  integrationsErpDiscountedPriceMappedFrom: 'Скидочная Цена ERP, Спланированная Из',
  integrationsErpEtaMappedFrom: 'Время поставки ERP, Спланированное Из',
  integrationsErpMappings: 'Планирование ERP',
  integrationsErpRetailPriceMappedFrom: 'Розничная Цена ERP, Спланированная Из',
  integrationsErpWholesalePriceMappedFrom: 'Оптовая Цена ERP, Спланированная Из',
  integrationsInvokeGetPartInfoFromClient: 'Запросить Номер Детали от Клиента',
  integrationsParseAddressForType: 'Приравнять Адрес к Типу',
  integrationsShoppingCartAndPartsTitle: 'Корзина и Детали',
  integrationsShoppingCartAvailability: '{action} Доступность Корзины',
  integrationsShoppingCartEtaFromErp: '{action} Предполагаемое Время Прибытия для Корзины из ERP',
  internalApiKey: 'Внутренний ключ API',
  internalDescription: 'Внутреннее Описание',
  internalName: 'Внутреннее Имя',
  internalNameOptional: 'Ваш идентификатор для тега',
  internalNameRequired: 'Необходимо указать имя',
  invalidDecimals: 'Разрешено не более двух символов после десятичного разделителя',
  invalidField: '{field} недействительно',
  invalidFileType: 'Тип файла не поддерживается. Полный список поддерживаемых файловых расширений приводится в Центре Поддержки Клиентов Documoto.',
  isSearchable: 'С Возможностью Поиска',
  insertDataField: 'Вставить поле данных',
  isSystem: 'Система',
  italianDescription: 'Описание На Итальянском',
  italianName: 'Название На Итальянском',
  item: '#',
  itemAlt: 'Пункт',
  itemCopied: '{item} скопировано',
  itemDeleted: '{item} удалено',
  itemInProgress: '{item} в процессе',
  itemInformation: '{item} информация',
  itemMustBeLteItem: '{itemA} должен быть равен или находиться перед {itemB}.',
  itemMustBeUnique: '{item} должен быть уникальным',
  itemMustBeUniqueAndNotEmpty: '{item} должен быть уникальным и является обязательным',
  itemName: 'Имя {item}',
  itemNotShown: '',
  itemSaved: '{item} сохранено',
  itemSpecification: 'Параметры Элемента',
  showChapterTOCForHiddenPages: 'Показать Главу с Содержанием для Скрытых Страниц',
  january: 'Январь',
  japaneseDescription: 'Описание На Японском',
  japaneseName: 'Название На Японском',
  job: 'Операция | Операции',
  jobManager: 'Диспетчер Операций',
  jobManagerMessage: 'Экспортируйте, публикуйте и распечатывайте или загружайте операции',
  july: 'Июль',
  june: 'Июнь',
  koreanDescription: 'Описание На Корейском',
  koreanName: 'Название На Корейском',
  label: 'Ярлык | Ярлыки',
  labelKey: 'Ключ к ярлыку',
  landscape: 'Альбом',
  laneConfiguration: 'Конфигурация дорожек',
  language: 'Язык',
  lastModifiedDate: 'Дата последнего изменения',
  lastGenerated: 'Последнее Созданное',
  lastName: 'Фамилия',
  lastNameEmpty: 'Поле фамилии должно быть заполнено',
  lastUpdatedOn: 'Последнее обновление:',
  layout: 'Макет',
  layoutOptions: 'Параметры Макета',
  ledger: 'Формат Ledger',
  left: 'Слева',
  legal: 'Формат Legal',
  letter: 'Формат Letter',
  library: 'Библиотека',
  libraryAdmin: 'Администратор Библиотеки',
  libraryBannerLogo: 'Логотип баннера библиотеки',
  librarySettings: 'Настройки Библиотеки',
  librarySettingsDescription: 'Эти настройки контролируют разные элементы и опции Библиотеки. С осторожностью применяйте изменения. Обратитесь в Службу Поддержки Documoto для получения подробной информации.',
  licenseConfiguration: 'Конфигурация лицензий',
  line: 'Строка',
  lineTool: 'Инструмент “Прямая”',
  link: 'Ссылка | Ссылки',
  listBox: 'Окно Списка',
  listBoxValue: 'Отобразить список Значения Поля | Отобразить список Значений Поля',
  listValue: 'Отобразить список Значения | Отобразить список Значений',
  load: 'Загрузить',
  loadMore: 'Загрузить еще',
  loading: 'Загрузка',
  locale: 'Место',
  login: 'Вход в систему',
  loginBackground: 'Фоновое изображение для входа в систему',
  loginGDPRMessageBeginning: 'На этом сайте используются файлы cookie. Продолжая пользоваться этим сайтом, вы разрешаете использование файлов cookie. Для получения дополнительной информации ознакомьтесь с ',
  loginGDPRMessageEnd: 'и наш',
  loginSSO: 'Вход SSO',
  logiReportName: 'Название Отчета Logi',
  logiReportToggle: 'Отчет Logi?',
  logo: 'Логотип | Логотипы',
  logout: 'Выход из системы',
  lookupLoadParts: 'Поиск/Загрузка Деталей из Documoto при импорте',
  lowerBound: 'Нижняя Граница',
  makePagesDivisibleBy: 'Сделать Страницы Кратными:',
  mainInfo: 'Основная Информация',
  mainLogo: 'Основной Логотип',
  manageItem: 'Редактировать {item}',
  management: 'Управление',
  march: 'Март',
  margin: 'Расстояние | Расстояния',
  markdown: 'Размер уценки',
  may: 'Май',
  media: 'Медиа | Медиа',
  mediaCategory: 'Категория Медиа | Категории Медиа ',
  mediaCategoryEditor: 'Редактор Категорий Медиа',
  mediaCategoryName: 'Название Категории Медиа',
  mediaCategoryNameMissing: 'Все категории медиа должны быть названы',
  mediaBookDeleteMessage: 'Удаление этих медиа удалит доступ к этому контенту в Библиотеке и Панели Администратора Контента и он станет недоступным для пользователей или Администраторов Контента. Какие-либо детали или страницы, найденные в других книгах, также не будут доступны в Библиотеке.',
  mediaDeleteMessage: 'Удаление этого шаблона удалит его из Панели Администратора Контента и он станет недоступным для копирования в новые Медиа.',
  mediaDeleteSuccessText: 'Он будет удален из списков после завершения индексации.',
  mediaDescription: 'Описание медиаресурса',
  mediaIdentifier: 'Идентификатор медиаресурса',
  mediaName: 'Имя медиаресурса',
  mediaPrintSettingsSaveSuccess: 'Настройки Печати Медиа сохранены',
  mediaWhereUsed: 'Где Используется Медиа',
  menu: 'Меню',
  merge: 'Объединить',
  mergeOrOverrideMessage: 'Перезаписать или объединить эти наименования?',
  mergeWarning: 'Импорт данных приведет к обновлению существующих деталей и добавлению новых записей',
  message: 'Сообщение',
  messageLevel: 'Уровень Сообщения',
  microsite: 'Микросайт | Микросайты',
  micrositeAdmin: {
    cardSubtitle: 'Сжатый файл содержимого и информация о записи HTML',
    cardTitle: 'Содержимое сайта',
    errorRequiredFile: 'Требуется ZIP-файл содержимого сайта',
    fieldFile: 'ZIP-файл содержимого сайта',
    fieldPoint: 'Точка входа HTML',
  },
  minArea: 'Минимальная Область',
  minAreaMessage: 'Требуется минимальное значение. Значение не может выходить за пределы диапазона от 1 до 1500.  Должно быть целочисленным значением.',
  moreInfo: 'Подробнее',
  multipleAlternatingPages: 'Множественное Чередование Страниц',
  multipleAlternatingPagesMessage: 'Диаграмма на страницах 1 и 3, список деталей на страницах 2 и 4.',
  mToM: 'M2M',
  multiplePages: 'Множественные Страницы',
  multiplePagesMessage: 'Диаграмма на одной странице, за которой следуют страницы со списком деталей',
  requiredField: 'Обязательное Поле',
  messageMessage: 'Требуется сообщение',
  metadata: 'Метаданные',
  mine: 'Мое',
  miscellaneous: 'Разное',
  mon: 'Пн',
  myCart: 'Моя Корзина | Мои Корзины',
  navigation: 'Навигация',
  nItemsCreated: '{n} {items} создано',
  nItemsDeleted: '{n} {items} удалено',
  nOfMItems: '{n} из {m} {items}',
  name: 'Имя',
  nameDescriptionHelpText: 'Имя и опциональное описание',
  new: 'Новый',
  newAPIKey: 'Новый Ключ API',
  newERPSystem: 'Новая ERP‑система',
  newItem: 'Новый {item}',
  newKeyGenerated: 'Создан Новый Ключ Интеграции; Скопировать и Сохранить для дальнейшего использования',
  newMediaCategory: 'Новая Категория Медиа',
  newPassword: 'Новый Пароль',
  newsItem: 'Новость | Новости',
  newTenant: 'Новый участник',
  newWidgetKey: 'Новый ключ виджета',
  newWidgetKeyGenerated: 'Новый ключ виджета создан, функция «Скопировать и сохранить» (Copy & Retain) доступна для будущего использования',
  noTenantSuppliers: 'Участнику не назначены поставщики.',
  noAPIKeys: 'Ключи API не определены.',
  node: 'Узел',
  noData: 'Данные Отсутствуют',
  noFilesSelected: 'Не Выбрано ни одного Файла',
  noIllustrationFound: 'Иллюстраций Не Обнаружено',
  noImageFound: 'Изображений Не Обнаружено',
  noItemAvailable: '{item} недоступно',
  noItemFound: '{item} — не найдено.',
  noItemSelected: '{item} не выбрано',
  noItemAddedToTarget: '{item} не добавлено к {target}. ',
  noItemsFound: 'Объектов Не Найдено',
  noMoreResults: 'Результатов больше нет',
  noFormsMessage: 'Формы Не определены.',
  noMediaDefined: 'Медиафайлы не определены',
  nonSearchableFieldsSuccessfullyUpdated: 'Поля, недоступные для поиска, успешно обновлены.',
  noPartsSelected: 'Детали не выбраны.',
  noPrice: 'Запрос Цены',
  noResults: 'Нет результатов',
  norwegianDescription: 'Описание На Норвежском',
  norwegianName: 'Название На Норвежском',
  noSearchableTags: 'Нет тегов с возможностью поиска',
  noSuggestions: 'Нет рекомендаций по поисковому запросу',
  notOrderable: 'Нельзя Заказать',
  notSelected: 'Не выбрано',
  noTagsFound: 'Теги не найдены',
  noUnsavedChanges: 'Нет Несохраненных Изменений',
  none: 'Нет',
  notImplemented: 'Не Реализовано',
  notification: 'Уведомление | Уведомления',
  notificationsMessage: 'Важные объявления и новости',
  notMapped: 'Не Преобразовано',
  november: 'Ноябрь',
  noWidgetKeys: 'Ключи виджетов не определены.',
  numeric: 'Числовой',
  numberOf: '№ {item}',
  october: 'Октябрь',
  of: 'из',
  off: 'Выкл.',
  offlineContent: 'Контент в Режиме Оффлайн',
  ok: 'OK',
  oldPassword: 'Старый пароль',
  on: 'Вкл.',
  opacity: 'Непрозрачность',
  open: 'Открыть',
  openItem: 'Открыть {item}',
  optimizeIndexJob: 'Оптимизация Индекса',
  options: 'Опции',
  openTab: 'Открыть ссылку в новой вкладке',
  order: 'Заказ | Заказы',
  orderable: 'С возможностью заказа',
  orderableDescription: 'Деталь можно добавить в корзину и купить',
  orderableLocal: 'Можно заказать на этой странице',
  orderableLocalBlocked: 'Деталь нельзя Заказать с Международной Доставкой',
  orderDisable: 'Отключить Историю Заказов',
  orderEmailRecipient: 'Получатель Электронного Сообщения о Заказе | Получатели Электронного Сообщения о Заказе',
  orderEntity: 'Элемент Заказа | Элементы Заказа',
  orderFieldUneditableRequired: 'В случае присвоения полю заказа / ценового предложения значения «Обязательное», а не «Редактируемое пользователем» завершение обработки формы заказа / ценового предложения может быть невозможным. Убедитесь, что ваши формы заказа / ценового предложения могут быть заполнены.',
  orderFieldsEmptyMessage: 'Поля заказа / ценового предложения не определены.',
  orderFieldsEmptyPrompt: 'Добавить поле заказа / ценового предложения',
  orderField: {
    nameMessage: 'В случае изменения названия поля заказа / ценового предложения обязательно обновите все использующие это поле формы заказов / ценовых предложений или форматы электронных сообщений.',
  },
  orderFieldCopyMessage: 'Введите уникальное имя для нового поля заказа / ценового предложения',
  orderForm: {
    uniqueNameInputHint: 'Введите уникальное имя для новой Формы'
  },
  orderFormField: 'Поле заказа / ценового предложения | Поля заказа / ценового предложения',
  orderFormLayout: 'Макет формы заказа / ценового предложения | Макеты формы заказа / ценового предложения',
  orderFormSetDefault: 'Настройка Формы Заказа/Предложения По Умолчанию',
  orderFormType: 'Форма заказа',
  orderLayout: 'Макет Заказа | Макеты Заказа',
  orderManagementApprover: 'Авторизатор Управления Заказом',
  orderManagementDisable: 'Отключить Администратора Управления Заказом',
  orderManagementViewer: 'Окно Просмотра Управления Заказом',
  orderNumber: 'Номер Заказа',
  orderQuoteEmail: 'Электронное сообщение о заказе/коммерческом предложении | Электронные сообщения о заказах/коммерческих предложениях',
  orderQuoteEmailFormat: 'Формат электронного сообщения о заказе/коммерческом предложении | Форматы электронных сообщений о заказах/коммерческих предложениях',
  orderQuoteEmailFormatCopyMessage: 'Выберите язык для нового формата электронного сообщения о заказе/коммерческом предложении.',
  orderQuoteFetchError: 'Ошибка получения данных формы заказа/коммерческого предложения',
  orderQuoteForm: 'Форма заказа/ценового предложения | Формы заказов/ценовых предложений',
  orderQuoteSaveConfirmation: 'Сохранить изменения в формах заказов/коммерческих предложений организации?',
  orderQuoteSaveError: 'Ошибка сохранения данных формы заказа/коммерческого предложения',
  orderQuoteFormSaved: 'Форма заказа / ценового предложения сохранена',
  orderStatus: 'Статус заказа / ценового предложения | Статусы заказа / ценового предложения',
  orderStatusDeleteMessage: 'Этот статус использовался для заказов, и его нельзя удалить.',
  orderSubmittedMessage: 'Успешно Отправлено',
  orderSuggestion: 'Рекомендация к Заказу | Рекомендации к Заказам',
  orderSuggestionEnable: 'Включить Рекомендации к Заказу',
  orderSuggestionRemovalWarning: 'Эта деталь больше не будет отображаться как рекомендация к заказу. | Эти детали больше не будут отображаться как рекомендация к заказу.',
  orderSuggestionThreshold: 'Максимальное количество Рекомендаций к Заказу',
  ordersLogo: 'Логотип Заказов',
  ordersMessage: 'История оформления заказов',
  orientation: 'Ориентация',
  org: 'Организация',
  orgEmailRecipients: 'Получатели Электронного Письма Организации',
  organization: 'Организация | Организации',
  organizationAdmin: 'Администратор Организации',
  organizationAdminBrowseFlow: 'Организация может использовать просмотр потока по умолчанию, или вы можете поменять его на другой просмотр потока.',
  organizationAdminMessage: 'Осуществляет управление пользователями и настройками организации',
  organizationEmpty: 'Нужно выбрать организацию',
  organizationEmailRecipients: 'Получатели Электронного Письма Организации',
  organizationEnforceAccessControlsForQuickAdd: 'Принудительный Контроль Доступа для Быстрого Добавления',
  organizationKey: 'Ключ Организации',
  organizationLibrarySettingsDescription: 'Эти настройки контролируют разные элементы и опции Библиотеки на уровне Организации. С осторожностью применяйте изменения. Обратитесь в Службу Поддержки Documoto для получения подробной информации.',
  organizationMoveToRoot: 'Перенесите сюда, чтобы перенести в корень',
  organizationName: 'Название организации',
  organizationRearrange: 'Изменить порядок Организаций',
  organizationRearrangeDescription: 'Организационная структура «родитель-ребенок» определяет многие наследуемые атрибуты Организации, такие как Настройки Печати, Стили и многое другое. Перетягивайте Организации для отображения нужной иерархии.',
  organizationReportSettingsSuccessMessage: 'Настройки отчетов сохранены',
  organizationSelect: 'Выбрать Организацию',
  organizationTags: 'Теги Организации',
  organizationsEmpty: 'Похоже, вы еще не создали ни одной организации',
  orgNameOnly: 'Только Имя Организации',
  other: 'Другое',
  otherSettings: 'Другие Настройки',
  outboundCustomerIdParameter: 'Исходное Имя Параметра ID Клиента ',
  outboundSessionIdParameter: 'Исходное Имя Параметра ID Сессии',
  outlineMode: 'Режим Контуров (Shift + O)',
  output: 'Вывод',
  outputCatalog: 'Каталог Вывода',
  outputDefinition: 'Определение Вывода | Определения Вывода',
  outputDefinitionDeleted: 'Определение Вывода удалено',
  outputDefinitionSaved: 'Определение Вывода Сохранено',
  outputFileName: 'Имя Файла Вывода',
  outputName: 'Имя Вывода',
  outputDestination: 'Настройка вывода',
  outputSpecification: 'Спецификация вывода',
  outputSubType: 'Подтип Вывода',
  outputType: 'Тип Вывода',
  overridden: 'Перезаписано',
  overriddenByOrg: 'Перезаписано Организацией',
  override: 'Перезаписать',
  overrideBrowseFlow: 'Перезаписать Просмотр Потока',
  overrideFacetLimitValue: 'Переопределение предельного значения грани',
  overrideHotpointScale: 'Перезаписать Масштабирование Ссылки-якоря',
  overridePdfNamingStandard: 'Переопределить стандарт именования файлов в формате PDF',
  overridePrintSettings: 'Перезаписать Настройки Печати',
  overrideFontSettings: 'Перезаписать Настройки Шрифта',
  overrideOrderForm: 'Переопределить форму заказа',
  overrideQuoteForm: 'Переопределить форму коммерческого предложения',
  overrideSvgOpacity: 'Перезаписать Затененность SVG',
  overrideWarning: 'Импорт данных заменит ваш текущий список спецификаций',
  owner: 'Собственник | Собственники',
  page: 'Страница | Страницы',
  pageBulkInsertConfirmTitle: 'Вы хотите завершить массовую вставку?',
  pageBulkInsertConfirmInsertAfter: 'Эта операция приведет к вставке {replacement} после {original} в {whereUsedBookCount} {bookUnits} и {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'Эта операция приведет к вставке {replacement} перед {original} в {whereUsedBookCount} {bookUnits} и {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'Эта операция приведет к вставке {replacement} и удалению {original} в {whereUsedBookCount} {bookUnits} и {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'Эта операция приведет к вставке {replacement} после {original} в {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'Эта операция приведет к вставке {replacement} перед {original} в {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'Эта операция приведет к вставке {replacement} и удалению {original} в {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Эта операция приведет к вставке {replacement} после {original} в {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Эта операция приведет к вставке {replacement} перед {original} в {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Эта операция приведет к вставке {replacement} и удалению {original} в {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Массовая вставка не будет обработана.',
  pageBulkInsertHelpText: 'Эта функция позволяет вставить эту исходную страницу {thisDisplayName} в выбранные части книги, где другая страница {originalDisplayName} уже существует. Как вариант, вы можете также удалить {originalDisplayName} из этих книг.',
  pageBulkInsertInsertAfter: 'Вставить {replacement} ПОСЛЕ {original}',
  pageBulkInsertInsertBefore: 'Вставить {replacement} ПЕРЕД {original}',
  pageBulkInsertInsertReplace: 'Вставить {replacement} и УДАЛИТЬ {original}',
  pageBulkInsertScopeAll: 'Все объекты {type} с целевой страницей {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Ни один объект {type} с целевой страницей {originalDisplayName}',
  pageBulkInsertScopeSome: 'Некоторые объекты {type} с целевой страницей {originalDisplayName}',
  pageBulkInsertStep1: 'Шаг 1: Найдите необходимую целевую страницу',
  pageBulkInsertStep2: 'Шаг 2: Определите, как вставить или заменить страницы',
  pageBulkInsertStep3: 'Шаг 3: Выберите книги, в которые нужно провести массовую вставку.',
  pageBulkInsertStep4: 'Шаг 4. Выберите шаблоны, в которые нужно провести массовую вставку.',
  pageBulkInsertSuccessTitle: 'Внесена операция по массовой вставке',
  pageBulkInsertSuccessBody: 'Изменения в оглавлении книги будут применены немедленно. Результаты поиска будут отображены после завершения индексации.',
  pageBulkErrorTooManyBooks: 'Ограничить выбранное количество книг не более чем на 1000 наименований',
  pageBuilder: 'Конструктор Страниц',
  pageComposer: 'Page Composer',
  pageCreated: 'Страница Создана',
  pageDeleteMessage: 'Функция удаления страниц начнет действовать немедленно в Библиотеке и контенте администратора. Удаленная страница будет доступна для поиска, пока не произойдет переиндексация в течение нескольких минут.',
  pageDeleteMessage2: 'Это действие невозможно отменить без помощи специалистов Службы поддержки Documoto. По истечении 90 дней удаленная запись и все ассоциации будут полностью стерты.',
  pageDescription: 'Описание страницы',
  pageFile: 'Файл Страницы',
  pageFilename: 'Имя файла страницы',
  pageFilenameDeleted: 'Будет удалено: {filename}.',
  pageFileRequired: 'Необходим файл страницы.',
  pageBomPartsDeleted: 'Такое количество частей на BOM‑странице больше не будет доступно через {filename}: {count}.',
  pageDraftsDeleted: 'Черновые страницы, связанные с этой страницей, будут полностью удалены.',
  pageFit: 'Подогнать по Странице',
  pageHeader: 'Заглавие Страницы',
  pageLabel: 'Страница | Страницы',
  pageLayout: 'Макет Страницы',
  pageName: 'Название страницы',
  pagePart: 'Часть Страницы | Части Страницы',
  pagePartTag: 'Тег Детали Страницы | Теги Детали Страницы',
  pagePartData: 'Данные о Деталях Страницы',
  pageUsageDataCouldNotBeLoaded: 'Данные об использовании страницы не удалось загрузить',
  pagePartLevelDetails: 'Страница запчасти, доступной для заказа/видимой, метки страницы запчасти',
  pagePartNumberVisible: 'Видимый Номер Детали Страницы',
  pagePartOrderable: 'Упорядочиваемая Деталь Страницы',
  pagePartOrderableDescription: 'Упорядочиваемая Деталь с этой страницы',
  pagePartVisibleDescription: 'Номер Детали, видимый на этой странице',
  pageUpdated: 'Страница Обновлена',
  pageViewer: 'Средство Просмотра Страниц',
  pageWidth: 'Ширина Страницы',
  pagesWhereUsed: 'Страница, на Которых Используется',
  parentName: 'Имя Родителя | Имена Родителей',
  part: 'Деталь | Детали',
  partCode: 'Код Детали | Коды Деталей',
  partCodeAddError: 'Нельзя добавить Код(ы) Детали',
  partCodeErrorEmpty: 'Не удалось сохранить: код Детали не может быть пуст',
  partCodeErrorInUse: 'Этот код детали используется деталями в Documoto и не может быть удален. Обратитесь в Службу Поддержки Documoto по адресу support{\'@\'}documoto.com.',
  partCodeErrorNotFound: 'Эти коды не удалены, потому что их не удалось найти. Пожалуйста, убедитесь, что все изменения были сохранены.',
  partCodesManager: 'Менеджер Кодов Деталей',
  partCodesMessage: 'Коды деталей могут использоваться вместо номеров деталей и часто используются для указания того, когда элементы спецификации устарели, недоступны или являются справочными элементами (например, «PNNA», «-», «REF»).',
  partCreated: 'Деталь Создана',
  partData: 'Данные о Деталях',
  partUpdated: 'Запчасть обновлена',
  partDebouncerEmailQueueJob: 'Очередь Электронной Почты',
  partDescription: 'Описание части',
  partLabel: 'Деталь | Детали',
  partLevelDetails: 'Цены запчастей, доступных для заказа/видимых, дополнительные пересчеты, метки запчастей',
  partName: 'Название Детали',
  partnerApiKey: 'Ключ API партнера',
  partnerRESTRequestLimit: 'Ограничение на число запросов REST партнера',
  partNumber: 'Номер Детали',
  partNumberDescription: 'Внутренний номер детали для поиска конечными пользователями',
  partNumberVisible: 'Видимый Номер Детали',
  partNumberVisibleDescription: 'Номер детали доступен для конечных пользователей',
  partNumberVisibleLocal: 'Отображается на этой странице',
  partNumberVisibleLocalBlocked: 'Номер Детали не Отображается Глобально',
  partOrderable: 'Упорядочиваемая Деталь',
  partRequired: 'Нужно указать хотя бы одну деталь',
  partTagProperty: 'Свойство Тега Детали | Свойства Тега Детали',
  partTag: 'Тег Детали | Теги Детали',
  partThumbnailDescription: '',
  partialSupport: 'Частичная Поддержка',
  partsListColumn: 'Колонка Списка Деталей | Колонки Списка Деталей',
  partsBook: 'Книга деталей | Книги деталей',
  partsColumns: 'Колонки Деталей',
  partsNotSelected: 'Изображение по умолчанию используется для иконки детали. Если не используется оригинальная иконка, Documoto будет отображать стандартную иконку по умолчанию.',
  partsPage: 'Страница Деталей | Страницы Деталей',
  partsPages: 'Страницы Деталей',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Исключить Коды Деталей из Списка Деталей',
    highlightAlternateRows: 'Выделять Поочередные Ряды',
    rotateImagesAutomatically: 'Автоматически Вращать Изображения',
    printBorderAroundImages: 'Печатать Рамку Вокруг Изображений',
    keepImagesOnLeft: 'Оставить Изображения Слева',
    keepBOMOnRight: 'Оставить BOM Справа',
    columnsUpdateErrorMsg: 'Произошла ошибка при попытке обновления Колонок Деталей',
    columnsUpdateSuccessMsg: 'Колонки Деталей обновлены',
    columnsLoadErrorMsg: 'Не удалось загрузить колонки деталей',
    printHotpointsOnImages: 'Печать Центральных Точек на Изображениях',
  },
  partsList: 'Список Деталей',
  partsListData: 'Данные Списка Деталей',
  partsListGridHeader: 'Заглавие Таблицы Списка Деталей',
  password: 'Пароль',
  passwordExpiredHeader: 'Срок действия пароля истек',
  passwordExpiredInfo: 'Срок действия вашего пароля истек. Новый пароль должен отвечать следующим условиям:',
  passwordCriteriaInfo: 'Новый пароль должен отвечать следующим условиям:',
  passwordBlankMessage: 'Необходимо указать пароль',
  passwordCannotBeSame: 'Не должен совпадать с текущим паролем.',
  passwordCasingMassage: 'Должен содержать как минимум один символ в нижнем и один в верхнем регистре.',
  passwordLowerCaseMessage: 'Пароль должен содержать по крайней мере один символ в нижнем регистре',
  passwordMinLengthMessage: 'Пароль должен содержать не менее 8 символов.',
  passwordNotMatchingMessage: 'Пароли не совпадают',
  passwordSpecialCharactersMessage: 'Пароль должен содержать по крайней мере один специальный символ',
  passwordUpperCaseMessage: 'Пароль должен содержать по крайней мере один символ в верхнем регистре',
  passwordWillExpire1Day: 'Срок действия пароля для входа истекает менее чем через 1 день',
  passwordWillExpire5Days: 'Срок действия пароля для входа истекает менее чем через 5 дней',
  passwordsMatch: 'Пароли совпадают',
  permission: 'Право доступа | Права доступа',
  phoneNumber: 'Номер Телефона',
  phoneNumberExt: 'Добавочный',
  phonePrimary: 'Основной Номер Телефона',
  phoneSecondary: 'Дополнительный Номер Телефона',
  pinFilter: 'Закрепить фильтр для поиска на странице результатов',
  pinned: 'Закрепленный',
  plusMoreOrgs: '+ {totalOrgCount} дополнительных Организаций',
  polishDescription: 'Описание На Польском',
  polishName: 'Название На Польском',
  populatePdfNameWith: 'Заполните поле «Имя файла в формате PDF»',
  populatePdfNameSelection: 'Заполните поле «Выбор имени файла в формате PDF»',
  port: 'Порт',
  portrait: 'Портрет',
  portugueseDescription: 'Описание На Португальском',
  portugueseName: 'Название На Португальском',
  postComment: 'Опубликовать Комментарий',
  postalCode: 'Почтовый Индекс',
  poweredBy: 'При поддержке',
  preferences: 'Предпочтения',
  preferencesDisable: 'Отключить Настройки Пользователя',
  prefix: 'Префикс | Префиксы',
  preview: 'Предварительный просмотр',
  previewOfName: 'Предварительный просмотр {name}',
  pricing: 'Цены',
  pricingDiscountEnabled: 'Отобразить Цену со Скидкой',
  pricingDisplayInCart: 'Отобразить Цены в Корзине',
  pricingDisplayInPartsList: 'Отображать Цены в Списке Деталей',
  pricingGlobal: 'Глобальные Цены',
  pricingGlobalDescription: 'Обычная цена, когда нет перезаписи Организации',
  pricingHidePricingButton: 'Включить Кнопку «Спрятать Цены»',
  pricingHidePricingButtonDisable: 'Выключить Кнопку «Спрятать Цены»',
  pricingOrganization: 'Цены Организации',
  pricingOrganizationDeleteConfirm: 'Вы уверены, что хотите удалить цены Организации?',
  pricingOrganizationDescription: 'Цены организации перезаписывают глобальные цены для всех пользователей в Организации и в их дочерних Организациях.',
  pricingOrganizationDuplicate: 'Эта организация уже была перезаписана',
  pricingOrganizationEmptyState: 'Не установлены параметры перезаписи для организации. Нажмите {add}, чтобы начать.',
  pricingRetailEnabled: 'Отобразить Розничную Цену',
  pricingWholesaleEnabled: 'Отобразить Оптовую Цену',
  primary: 'Основное',
  print: 'Печать',
  printerLane: 'Дорожка принтера',
  printJob: 'Операция на Печать',
  printLogo: 'Логотип для печати',
  printSetting: 'Настройки Печати',
  printSettingDeleteFail: 'Не удалось Удалить Настройки Печати',
  printSettingSaveFail: 'Не удалось Сохранить Настройки Печати',
  printSettingEmptyMessage: 'Не заданы Настройки Печати. Добавьте Настройки Печати, чтобы начать.',
  printSettingSetDefaultSuccess: 'Настройки Печати по умолчанию установлены',
  printSettingUpdateFail: 'Не удалось Обновить Настройки Печати',
  printSettingCopyMessage: 'Введите уникальное имя для Настроек Печати.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Созданы Настройки Печати',
  printSettings: 'Настройки Печати',
  printSettingsLogo: 'Логотип Настроек Печати',
  printing: 'Печать',
  printingDisabled: 'Запретить Всю Печать',
  privacyPolicy: 'Политика Конфиденциальности',
  proceed: 'Продолжить',
  process: 'Обработать',
  processContent: 'Обработать Контент',
  processing: 'Обработка',
  processToDraft: 'Обработка с созданием черновика',
  processedPlzToDraft: 'PLZ-файлы, обработанные с созданием черновиков',
  production: 'Производство',
  professional: 'Профессиональный',
  profile: 'Профиль',
  prohibitExportDownloadOfContent: 'Запретить экспорт/скачивание содержимого',
  prohibitExportDownloadOfContentHelpText: 'Если выбран вариант \"Включить\", пользователи (обладающие правами) не смогут экспортировать или скачивать это содержимое.',
  property: 'Свойство | Свойства',
  province: 'Область',
  public: 'Публичный',
  publish: 'Опубликовать',
  publisherLane: 'Дорожка издателя',
  publisherManualLane: 'Дорожка издателя (вручную)',
  publishThumbnailJob: 'Опубликовать Эскиз',
  publishDocuments: 'Опубликовать Документы',
  publishDraft: 'Опубликовать Черновик',
  publisher: 'Публикатор | Публикаторы',
  publishingErrors: 'Ошибки Публикации',
  publishingErrorsWithName: 'Ошибки Публикации {name}',
  publishingInProgress: 'Не удалось отправить, выполняется публикация',
  publisherJob: 'Операция Публикатора',
  publisherMessage: 'Создание контента, управление контентом и настройки печати',
  purchaseOrder: 'Заказ на Поставку',
  purchaseOrderTemplateEnabled: 'Включить Шаблоны Заказа',
  purchaseOrderTemplateDisabled: 'Выключить Шаблоны Заказа',
  qty: 'Кол-во',
  quantity: 'Количество',
  query: 'Запрос',
  quickAdd: 'Быстрое Добавление',
  quickAddHelpText: 'Начните вводить текст, чтобы найти детали для добавления в корзину.',
  quickAddInCartEnabled: 'Включить Быстрое Добавление в Корзине',
  quickLinks: 'Быстрые Ссылки',
  quote: 'Цитата | Цитаты',
  quoteFormType: 'Форма коммерческого предложения',
  quoteTemplateDisabled: 'Выключить Шаблоны Расценки',
  quoteTemplateEnabled: 'Включить Шаблоны Расценки',
  range: 'Диапазон',
  rearrange: 'Перестроить',
  rebuildIndexJob: 'Перестройка Индекса',
  recentSearches: 'Недавние поисковые запросы',
  recentlyAdded: 'Недавно Добавлено',
  recentlyEdited: 'Недавно Отредактировано',
  recentlyViewed: 'Недавно Просмотрено',
  rectangle: 'Прямоугольник',
  redirect: 'Перенаправить',
  refresh: 'Обновить',
  related: 'Похожее',
  relatedDescription: 'Свяжите существующие медиа в библиотеке с этим элементом «{type}». Обратите внимание, что связанные медиа должны иметь активные настройки управления доступом, чтобы пользователи могли видеть их на вкладке \'Похожее\'.',
  relatedInfo: 'Сопутствующая Информация',
  relatedView: 'Просмотреть Похожие Медиа',
  relationship: 'Отношения | Отношения',
  releaseNotes: 'Примечания к Версии',
  rememberMe: 'Запомнить меня',
  remainingWidth: 'Остаточная Ширина',
  remove: 'Удалить',
  removeAll: 'Удалить Все',
  removeItemConfirmation: 'Вы уверены, что хотите удалить {item}?',
  removeItemCount: 'Действительно удалить {count} {type}?',
  replace: 'Заменить',
  replaceExisting: 'Заменить Существующее',
  report: 'Отчет | Отчеты',
  reportAnIssue: 'Сообщить о Проблеме',
  reportAnIssueMessage: 'Письмо успешно отправлено.',
  reportCatalogName: 'Имя Каталога Отчетов',
  reportCatalogNotSet: 'Каталог отчетов не был определен для данного отчета.',
  reportDefinition: 'Определение Отчета | Определения Отчета',
  reportDefinitionDeleteError: 'Не удалось удалить определение отчета',
  reportsEmptyState: 'Отчетов нет',
  reportExportTimeWarning: 'В зависимости от количества записей и выбранного формата операция экспорта может занять несколько минут.',
  reporting: 'Отчетность',
  reportingMessage: 'Доступ к различным отчетам и статистике',
  reportSettings: 'Настройки Отчетов',
  reportsLibrary: 'Библиотека Отчетов',
  requestForQuote: 'Запрос Ценового Предложения',
  requestFormType: 'Форма коммерческого предложения',
  required: 'Обязательный',
  requiredItem: 'Необходим {item}.',
  requiredMessage: '{item} обязательный.',
  requiredMessageAndUnique: 'Элемент «{item}» является обязательным и должен быть уникальным.',
  requireAuthentication: 'Обязательная Идентификация',
  requiredReading: 'Обязательное Чтение',
  requiredReadingMessage: 'Просмотрите следующее:',
  requiredUrlMessage: 'Необходима правильно оформленная ссылка.',
  reset: 'Сброс',
  resetPassword: 'Сбросить Пароль',
  resetPasswordError: 'Не удалось сбросить пароль. Повторите попытку.',
  resetPasswordTokenError: 'Маркер сброса пароля недействителен.',
  resetZoom: 'Сброс Масштабирования',
  resource: 'Ресурс | Ресурсы',
  resize: 'Изменить размер',
  resizeThumbnails: 'Автоматически изменять размер эскизов',
  result: 'Результат | Результаты',
  resultFor: 'Результат для {item} | Результаты для {item}',
  retail: 'Розница',
  retailPrice: 'Розничная Цена',
  retailTotal: 'Суммарная Розничная Цена',
  return: 'Вернуться',
  returnToItem: 'Вернуться к {item}',
  returnToPartList: 'Вернуться к Списку Деталей',
  returnToMedia: 'Вернуться к Медиа',
  returnToTemplate: 'Вернуться к Шаблону',
  returnToViewer: 'Перейти к окну просмотра',
  revertChanges: 'Отменить Изменения',
  right: 'Справа',
  romanianDescription: 'Описание На Румынском',
  romanianName: 'Название На Румынском',
  rootLevelBrowseFlow: 'Корневой Уровень',
  row: 'Ряд',
  russianDescription: 'Описание На Русском',
  russianName: 'Название На Русском',
  sampleData: 'Образец Данных',
  samplePreview: 'Предварительный просмотр образца',
  sandbox: 'Песочница',
  sat: 'Сб',
  save: 'Сохранить',
  saveChanges: 'Сохранить изменения в {item}? {name}',
  saveChangesToItem: 'Сохранить изменения в {item}?',
  saved: 'Сохранено',
  saveNewAPIKey: 'Новый Ключ Интеграции не сохранен; Вернитесь для завершения настройки',
  saveNewWidgetKey: 'Не удалось сохранить новый ключ виджета. Вернитесь к завершению настройки.',
  search: 'Поиск',
  searchableFieldsSuccessfullyUpdated: 'Поля, доступные для поиска, успешно обновлены.',
  searchAllContent: 'Поиск по всему содержимому',
  searchConfiguration: 'Конфигурация поиска',
  searchCriteria: 'Критерии Поиска',
  searchDocumoto: 'Искать на Documoto',
  searchFeatures: 'Функции поиска',
  searchFieldConfigDescription: 'Нижеуказанные параметры поиска в системных полях применимы только к странице результатов поиска, а также к инструментам «Общий поиск по библиотеке» и «Быстрое добавление в корзину». Других функций поиска (например, «Поиск в центре администрирования» и «Библиотека использования») это не касается.',
  searchInContent: 'Поиск в {contentName}...',
  searchingInLanguage: 'Поиск только в {lang}',
  searchingInTwoLanguages: 'Поиск только в {lang1} и {lang2}',
  searchPlaceholder: 'Поиск по всему содержимому…',
  searchPrompt: 'Поиск по всему содержимому…',
  searchProperty: 'Свойство Поиска | Свойства Поиска',
  searchResultsConfiguration: 'Конфигурация результатов поиска',
  searchSettings: 'Параметры поиска',
  searchSettingsDescription: 'Эти параметры управляют использованием, функциями и результатами поиска по Библиотеке.',
  searchTerm: 'Искать Термин',
  searchTips: 'Советы по поиску:',
  searchable: 'С возможностью поиска',
  searchableTag: 'Тег с возможностью поиска',
  section: 'Раздел',
  select: 'Выбрать',
  selectAField: 'Выберите поле {field}',
  selectAll: 'Выбрать Все',
  selectAndClose: 'Выбрать и Закрыть',
  selected: 'Выбрано',
  selectedTargetPage: 'Выбранная Целевая Страница',
  selectChapters: 'Выбрать Главу (-ы)',
  selectChildren: 'Выбрать Дочерние элементы',
  selectFields: 'Выбрать поля {fields}',
  selectItem: 'Выбрать {item}',
  selectMedia: 'Выбрать Носитель',
  selectPage: 'Выбрать Страницу',
  selectPages: 'Выбрать Страницу (-ы)',
  selectTargetPage: 'Выбрать Целевую Страницу',
  send: 'Отправить',
  september: 'Сентябрь',
  serbianDescription: 'Описание На Сербском',
  serbianName: 'Название На Сербском',
  serviceUri: 'Сервисная ссылка',
  sessionLog: 'Журнал сеансов',
  set: 'Установить',
  setAsDefault: 'Установить По умолчанию',
  settings: 'Настройки',
  settingsMessage: 'Обновите свои параметры пользователя и пароль',
  sequence: 'Последовательность',
  shape: 'Форма',
  shapeTool: 'Инструмент “Фигура”',
  shared: 'Общий доступ',
  sharedCart: 'Совместная Корзина | Совместные Корзины',
  sharedCartDeleteContent: 'Это удалит Совместную Корзину для всех Организаций, для которых она была создана.',
  sharedCartOrganizationContent: 'Выберите Организации, которые могут использовать эту Совместную Корзину.',
  sharedShoppingCartsEnabled: 'Включить Совместные Корзины',
  shoppingCartExportJob: 'Экспорт Корзины Покупок',
  shoppingCartLoadingLongTime: 'Корзина загружается. Значки корзины появятся после завершения процесса. Если проблема сохраняется, обратитесь за поддержкой к администратору Documoto.',
  showExportFromSearch: 'Разрешить Экспорт из Результатов Поиска',
  showExportFromSearchHelp: 'Разрешить экспорт списка книг, показанных в результатах поиска',
  showHelp: 'Показать Справку',
  showIdentifierInSearch: 'Показать идентификатор медиа в поиске',
  showItem: 'Показать {item}',
  showLess: 'Показать Меньше',
  showMediaDescriptionFilter: 'Показать Фильтр Описания Медиа',
  showMediaIdentifierFilter: 'Показать Фильтр Идентификации Медиа',
  showMediaNameFilter: 'Показать Фильтр Имени Медиа',
  showMore: 'Показать Больше',
  showOnlyExactMatches: 'Показывать только точные совпадения',
  showOrganizationFilter: 'Показать Фильтр Организации',
  showPartNameFilter: 'Показать Фильтр Имени Детали',
  showPartNumberFilter: 'Показать Фильтр Номера Детали',
  showRecentlyAdded: 'Показать Недавно Добавленное',
  showRecentlyViewed: 'Показать Недавно Просмотренное',
  showSearchButton: 'Показать Кнопку Поиска на Домашней Странице',
  showSearchButtonHelp: 'Показать кнопку \'Перейти к Поиску\' в верхнем меню Библиотеки',
  signature: 'Подпись',
  size: 'Размер',
  snapshot: 'Мгновенный снимок | Мгновенные снимки',
  solrSlaveSvrOverride: 'Переопределение базовых URL подчиненных серверов SOLR',
  sortAsc: '{value} A–Я',
  sortDesc: '{value} Я–A',
  sortRelevant: 'Самое Актуальное',
  sortType: 'Сортировать по Типу',
  spanishDescription: 'Описание На Испанском',
  spanishName: 'Название На Испанском',
  square: 'Квадрат',
  ssoConfiguration: 'Конфигурация SSO',
  ssoIdpMetadataUrl: 'URL метаданных IDP SSO',
  ssoLoginMaxAuthenticationAgeSeconds: 'Максимальный срок аутентификации входа SSO, в секундах',
  ssoSamlDnAttributeName: 'Имя атрибута DN SAML SSO',
  ssoSamlEmailAttributeName: 'Имя атрибута электронной почты SAML SSO',
  ssoSamlMemberofAttributeName: 'Имя атрибута участника SAML SSO',
  ssoSniForSsoMetadataRetrieval: 'Использовать SNI для получения метаданных SSO',
  standard: 'Стандарт',
  standardUserGroup: 'Стандартная группа пользователей',
  startDate: 'Дата Начала',
  startDateRequired: 'Требуется указать дату начала.',
  state: 'Состояние',
  static: 'Статический',
  status: 'Статус',
  statusCode: 'Код Статуса | Коды Статуса',
  stitchPages: 'Сшитые Страницы',
  storeFrontAdmin: 'Администратор онлайн-магазина',
  storefront: 'Онлайн-магазин',
  storefrontIntegration: 'Интеграция онлайн-магазина | Интеграции онлайн-магазина',
  storefrontIntegrationSubtitle: 'Эти настройки контролируют разные элементы и опции Интеграции Онлайн-магазина. С осторожностью применяйте изменения. Обратитесь в Службу Поддержки Documoto для получения подробной Информации.',
  storefrontSettings: 'Настройки Онлайн-магазина',
  storefrontSettingsDescription: 'Эти настройки контролируют разные элементы и опции Онлайн-магазина. С осторожностью применяйте изменения. Обратитесь в Службу Поддержки Documoto для получения подробной информации.',
  storefrontSettingsOrgDescription: 'Эти настройки контролируют разные элементы и опции Онлайн-магазина на уровне Организации. С осторожностью применяйте изменения. Обратитесь в Службу Поддержки Documoto для получения подробной информации.',
  storeFilterValues: 'Сохранить значения фильтров',
  storeFilterValuesDescription: 'Если включить этот параметр, будут сохраняться значения, установленные пользователями в фильтрах страницы поиска для этого тега.',
  stroke: 'Штрих',
  style: 'Стиль | Стили',
  styles: {
    colorHotpoint: 'Основная точка по умолчанию',
    invalidHexcode: 'Недействительный шестнадцатеричный код',
    inheritedFromOrg: 'Унаследовано от организации {org}',
    inheritedFromSystem: 'Унаследовано от системного значения по умолчанию',
    inheritedFromTenant: 'Унаследовано от арендатора',
    organization: 'Стиль организации | Стили организации',
    organizationSaved: 'Стили организации сохранены',
    sampleNavigation: 'Навигационные вкладки',
    samplePrimary: 'Навигация/Кнопки',
    sampleSelectedHotpoint: 'Выбранные основные точки',
    tenant: 'Стиль арендатора | Стили арендатора',
    tenantSaved: 'Стили арендатора сохранены'
  },
  subChapter: 'Подраздел | Подразделы',
  subject: 'Тема',
  subjectMessage: 'Необходимо указать Тему',
  submit: 'Отправить',
  submitPurchaseOrder: 'Заказ на поставку',
  submitRequestForQuote: 'Запрос Ценового предложения',
  submitted: 'Отправлено',
  submitter: 'Отправитель',
  submittedBy: 'Отправил(-а)',
  submitterEmail: 'Адрес электронной почты отправителя',
  submitterFirstName: 'Имя отправителя',
  submittedFilesMessage: 'Отправлено файлов: {fileCount}.',
  submitterLastName: 'Фамилия отправителя',
  submitterOrgName: 'Название организации отправителя',
  submitterUserName: 'Имя пользователя отправителя',
  success: 'Успех',
  imageSaveSuccess: 'Успешно Обновленные Изображения',
  successNotificationDuration: 'Добавить в Корзину — Продолжительность Всплывающего Уведомления об Успешном выполнении',
  suffix: 'Суффикс | Суффиксы',
  suggestedKeywords: 'Рекомендуемые ключевые слова',
  suggestedResults: 'Рекомендуемые результаты',
  suggestion: 'Предложение | Предложения',
  sun: 'Вс',
  superadmin: 'Суперадминистратор',
  superAdminSettings: "Настройки суперадминистратора",
  supersede: 'Заменить',
  supersedeComplete: 'Вы хотите завершить замену?',
  supersedeDiscardChanges: 'Отменить вашу замену?',
  supersedeMainText: 'Замена заменяет существующую деталь одной или несколькими деталями на каждой Странице Деталей, где найдена старая деталь. Старый номер детали удалится, но останется в поиске по средствам тегов, и таким образом контент можно найти, используя старый номер или новый(е) номер(а).',
  supersedeMessage: 'Обновления информации о деталях будут сразу применены. Поиск отобразит обновления через несколько минут.',
  supersedeNotProcessed: 'Ваша замена не будет обработана.',
  supersedeReplacePart: 'Найти одну или более деталей, которые заменят деталь',
  supersedeSuccessful: 'Замена прошла Успешно',
  supersedeWith: 'Заменить Деталь На…',
  supplier: 'Поставщик | Поставщики',
  supplierKey: 'Ключ поставщика',
  supplierDescription: 'Компания, которая поставляет эту деталь',
  supportedImageFormats: 'Форматы изображения, которые поддерживаются: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Неподдерживаемый файл; поддерживаемые файлы черновиков: MDZ, PLZ',
  surroundWithQuotes: 'Для нахождения точных совпадений заключите поисковые термины в кавычки.',
  svgFinder: 'Система поиска SVG',
  swedishDescription: 'Описание На Шведском',
  swedishName: 'Название На Шведском',
  system: 'Система',
  systemAdmin: 'Системный Администратор',
  systemFields: 'Системные Поля',
  systemFieldSearchAndFiltering: 'Поиск и фильтрация системных полей',
  systemStatus: 'Системный Статус',
  systemName: 'Название системы',
  systemVersion: 'Версия системы',
  tabDelimited: 'с разделителями-знаками табуляции',
  tableOfContents: 'Оглавление',
  tabloid: 'Формат «Tabloid»',
  tag: 'Тег | Теги',
  tagName: 'Название тега',
  tagEditor: {
    rangeBoundLengthInvalid: 'Значение не должно превышать ограничение по символам: {limit}.',
    tagEditorModal: 'Редактор Тегов',
    filterPrompt: 'Отображение первых 100 значений. Используйте фильтр, чтобы увидеть больше.'
  },
  tagSearchAndFiltering: 'Поиск и фильтрация тегов',
  tagSearchAndFilteringHelpText: 'Подсказка: Некоторые параметры поиска и фильтров также можно настроить в «Редакторе тегов» инструмента «Менеджер тегов», который можно найти здесь: ',
  tagSubmitAffected: 'Любой контент {type} будет автоматически сбрасывать тег {tag} вместе с изменениями.',
  tagDeleteAffectedCount: 'Это удалит тег и информацию о теге из {count} наименований(я).',
  tagDeleteAffectedNone: 'Нет наименований, которые используют этот тег.',
  tagManagerDeleteWarning: 'Все существующие медиа с {deleteCategoryName} утратят эту категорию после удаления.',
  tagsDisplayMore: 'Отображение первых 100 значений. Используйте фильтр, чтобы увидеть больше.',
  tagsManager: 'Тег-менеджер',
  tagValue: 'Значение тега | Значения тега',
  tagValueMissing: 'Вам нужно ввести значение тега',
  tagValueMultiple: 'Разделитель точка с запятой для множественного ввода',
  tagValueTooLong: 'Значение тега превышает лимит в 25 символов | Значения тега превышают лимит в 25 символов',
  taxon: 'Таксон',
  taxonomy: 'Таксономия',
  template: 'Шаблон | Шаблоны',
  templateEmailRecipients: 'Шаблон Получателей Электронного Письма',
  templateLayout: {
    addRow: 'Добавить строку',
    addItem: 'Добавить элемент',
    deleteRow: 'Удалить строку',
    deleteColumn: 'Удалить столбец',
    fullWidth: 'Полная ширина',
    oneColumn: 'Один столбец',
    threeColumns: 'Три столбца',
    twoColumns: 'Два столбца'
  },
  tenant: 'Участник | Участники',
  tenantAdmin: 'Администратор Участников',
  tenantAdminMessage: 'Управляет настройками всех участников',
  tenantCleanerJob: 'Обслуживание Клиентов',
  tenantConfiguration: 'Конфигурация участника',
  tenantEmailRecipients: 'Получатели Электронного Письма для Участников',
  tenantEmailRecipientsOverrideMessage: 'Это будет перезаписано, если указаны Получатели Электронного Письма Организации',
  tenantIndexQueueJob: 'Очередь Индексов Клиентов',
  tenantKey: 'Ключ участника',
  tenantKeyError: 'Ключ участника не является уникальным',
  tenantKeyMessage: 'Ключ участника должен быть уникальным',
  tenantLabelDeleteMessage: 'Удаление этого ярлыка вернет имя Documoto по умолчанию.',
  tenantReindexModulus: 'Модуль реиндексирования участника',
  tenantSettings: {
    collapseTOCLabel: 'Свернуть Оглавление на Подходящей Странице',
    defaultLocaleLabel: 'Регион по умолчанию',
    defaultLocaleMessage: 'Регион участника по умолчанию.',
    deleteMissingTranslationsOnPublishLabel: 'Удалить отсутствующие переводы при публикации?',
    deleteMissingTranslationsOnPublishMessage: 'Если этот параметр включен, переводы компонентов удаляются при публикации',
    defaultSupplierLabel: 'Поставщик по умолчанию.',
    defaultSupplierMessage: 'Поставщик участника по умолчанию.',
    enableChapterIndexingMessage: 'Индексировать разделы',
    enableChapterIndexingLabel: 'Включение индексирования разделов медиа',
    enableIndexingLabel: 'Указатель Публикаций',
    enableIndexingMessage: 'Включает отображение указателя для публикации.',
    helpUrlLabel: 'Ссылка «Помощь» по умолчанию',
    helpUrlMessage: 'Ссылка для альтернативной системы поддержки.',
    hotPointLinkPageInBookOnlyLabel: 'Страница со Ссылками Hotpoint Только в Книге',
    hotPointLinkPageInBookOnlyMessage: 'Ссылки Hotpoint на Страницах Только в Книге',
    mediaTimestampLabel: 'Обновить Отметку Времени для Медиа на Панели Управления Доступом',
    mediaTimestampMessage: 'Включите, если изменение категорий носителей контроля доступа должно приводить к обновлению временной метки носителя.',
    publisherDefaultUom: 'Единицы Измерения Публикатора По умолчанию',
    publishingContactLabel: 'Публикация Контактного Электронного Адреса',
    publishingContactMessage: 'Электронный адрес по умолчанию, который будет получать сообщения при уведомлении о проблеме с контентом.',
    replaceHotpointLabel: 'При Публикации всегда Заменяйте Ссылки-якоря',
    replaceHotpointMessage: 'Только для публикации в Docustudio.',
    ssoLoginSystemLabel: 'Система Входа SSO',
    ssoLoginSystemMessage: 'Система Поставщика Идентификационной информации для SSO.',
    userPasswordExpirationLabel: 'Срок действия пароля, дни',
    userPasswordExpirationMessage: 'Количество дней, по истечении которого пароль необходимо изменить; если это поле пустое, срок действия пароля не истекает',
    lockPartTrOnPublishLabel: 'Блокировать переводы компонентов при публикации',
    lockPartTrOnPublishMessage: 'Позволяет запретить изменение переводов компонентов во время публикации',
    exportPgAsHashLabel: 'Экспортировать имя файла страницы в виде хеш-ключа',
    exportPgAsHashMessage: 'Использовать хеш-ключи в качестве имен файлов страниц в экспортированном содержимом'
  },
  tenantSettingsTitle: 'Настройки Участника',
  tenantSettingsSaved: 'Настройки Участника Сохранены',
  tenantSettingsSubtitle: 'Эти настройки управляют свойствами и поведением на уровне участников. Применяйте изменения осторожно. Чтобы узнать больше, обратитесь в Службу Поддержки Documoto.',
  tenantSuppliers: 'Поставщики участников',
  tenantLabelsPageText: 'Ярлыки используются для персонализации имен многих объектов, которые будут найдены в Библиотеке Documoto, и на Администраторской панели.',
  tenantPrintSettingsDescription: 'Выберите настройки печати по умолчанию для всех участников. Это будет применено ко всем печатным книгам, за исключением тех, которые были исключены в Организации и Медиа.',
  tenantType: 'Тип участника',
  tenantWebserviceUser: 'Пользователь веб-службы участника',
  text: 'Текст',
  textArea: 'Область Текста',
  textField: 'Текстовое Поле',
  textFile: 'Текстовый Файл (CSV, TXT)',
  textTool: 'Инструмент “Текст”',
  threeD: {
    addFile: 'Добавить файл детали в 3D',
    beforeSaveModal: {
      title: 'В Documoto уже существует CAD-файл с таким именем.',
      message: 'Заменить этот файл повсюду или прикрепить его только к этой детали? Если выбрать вариант замены файла, произойдет следующее:',
      messageBulletOne: '{count} деталь(-и) будут обновлены с помощью нового CAD-файла',
      messageBulletTwo: 'Предыдущая версия этого файла будет удалена из файловой системы Documoto',
      tip: 'Совет. Чтобы предотвратить перезапись файлов, можно изменить имя файла, который вы собираетесь прикрепить, перед его импортом.'
    },
    deleteTitle: 'Вы действительно хотите удалить это 3D-представление?',
    drawMode: {
      label: 'Режим рисования',
      hiddenLines: 'Скрытые линии',
      shaded: 'Закрасить',
      wireframe: 'Каркас',
      wireframeShaded: 'Закрасить каркас',
    },
    explode: 'Развертка',
    fileSizeModal: {
      message: 'Максимально допустимый размер файла в Documoto — 25 мегабайт. Файл, который вы пытаетесь загрузить, превышает максимальный размер и не может быть загружен. Нажмите OK, чтобы продолжить.',
      title: 'Превышен максимальный размер изображения',
    },
    orientationAndProjection: {
      back: 'Сзади',
      bottom: 'Снизу',
      front: 'Спереди',
      isometric: 'Изометрическое',
      left: 'Слева',
      orthographic: 'Ортогональное',
      projection: 'Проекция',
      perspective: 'В перспективе',
      right: 'Справа',
      top: 'Сверху'
    },
    resetCamera: 'Сбросить камеру',
    slice: {
      label: 'Разрез',
      planeX: 'Режущая плоскость X',
      planeY: 'Режущая плоскость Y',
      planeZ: 'Режущая плоскость Z',
      reset: 'Сброс режущей плоскости',
      section: 'Переключатель сечения режущей плоскости',
      visibility: 'Переключатель видимости режущей плоскости',
    },
    supportedFilesMessage: 'Documoto поддерживает множество форматов CAD-файлов. Обратитесь в Центр поддержки, если вы не уверены, что ваш формат CAD-файла поддерживается.',
    toolsLabel: 'Инструменты 3D',
    topAssemblyFilename: 'Имя файла сборки верхнего уровня',
    topAssemblyFilenameRequired: 'Имя файла сборки верхнего уровня является обязательным',
    viewerTitle: 'Просмотр 3D',
  },
  thu: 'Чт',
  thumbnail: 'Миниатюра',
  thumbnailDeleted: 'Миниатюра Успешно Удалена',
  thumbnailDeletedError: 'Не удалось Удалить Миниатюру',
  thumbnailUpdated: 'Миниатюра Успешно Обновлена',
  thumbnailUpdatedError: 'Не удалось Обновить Миниатюру',
  thumbnailReapplyWarning: 'Система может повторно применить миниатюру, основываясь на контенте медиа. Это может занять несколько минут.',
  thumbnailResizeTooltip: 'Если флажок установлен, измененный размер не должен превышать 500 × 500 пикселей.',
  title: 'Заглавие',
  to: 'Адресат',
  toastNotifications: 'Всплывающие Уведомления',
  toDate: 'Конечная Дата',
  toc: 'Оглавление',
  tocClipBoardSuccessTitle: 'Скопировано в буфер обмена',
  tocClipBoardWarningText: 'ВНИМАНИЕ: Не сохраненные изменения нельзя копировать в ваш буфер обмена',
  tocClipBoardWarningTitle: 'Скопировано в буфер обмена',
  tocDisplayingFirstValues: 'Отображение первых 100 значений. Используйте фильтр, чтобы увидеть больше.',
  tocDuplicateWarningText: 'В главе была обнаружена страница-дубликат. Дубликат был удален.',
  tocEmptyMessage: 'Похоже, вы еще не создали Оглавление',
  toGetStarted: 'чтобы начать.',
  tocMissingChapterName: 'Отсутствует Название Главы',
  tocModalTableCaption: 'Редактор Тегов',
  tocNothingSelected: 'Ничего Не выбрано',
  tocOpenEditor: 'Открыть Редактор',
  tocSaveRequired: 'Необходимо выполнить сохранение, прежде чем редактировать главу',
  tocState: 'Исходное Состояние Оглавления',
  tocThumbnailDeleteFailTitle: 'Не удалось Удалить Миниатюру',
  tocThumbnailDeleteSuccessTitle: 'Миниатюра Успешно Удалена',
  tocThumbnailUploadFailTitle: 'Не удалось Загрузить Миниатюру',
  tocThumbnailUploadSuccessTitle: 'Миниатюра Успешно Загружена',
  tocTranslationMessage: 'Имя и опциональное описание этой главы',
  tocUntitledPage: 'Страница без названия',
  tooManySelections: 'Список превышает ограничения выборки ({limit})',
  togglePage: 'Переключатель Страниц',
  top: 'Верх',
  total: 'Сумма',
  totalItems: 'Сумма Наименований',
  translation: 'Перевод | Переводы',
  translationHelpTextItem: 'Имя и опциональное описание этого(й) {item}',
  true: 'Да',
  tryAgain: 'Пожалуйста, попробуйте снова.',
  tue: 'Вт',
  turkishDescription: 'Описание На Турецком',
  turkishName: 'Название На Турецком',
  twoSided: 'Двусторонняя',
  type: 'Тип | Типы',
  typeAtLeastTwoChars: 'Чтобы увидеть список совпадений, введите не менее двух символов.',
  typeToConfirm: 'Введите {value} для подтверждения',
  typography: 'Типография',
  ukraineDescription: 'Описание На Украинском',
  ukraineName: 'Название На Украинском',
  unableToRetrieveResults: 'Невозможно получить результаты для вашего запроса/поиска',
  unauthorized: 'Несанкционированный',
  unknownError: 'Неизвестная ошибка. Пожалуйста, обратитесь в службу поддержки Documoto по адресу support{\'@\'}documoto.com.',
  unknownPart: 'Неизвестная Деталь',
  unknownType: 'Неизвестный тип',
  unsavedAPIKeyWarning: 'В случае невыполнения Возврата Ключ API будет утерян',
  unsavedChanges: 'У вас имеются несохраненные изменения. Продолжить?',
  unsavedChangesWarning: 'Ваши изменения будут утрачены, если их не сохранить.',
  unsavedWidgetKeyWarning: 'Если вы не вернетесь, ваш ключ виджета будет утерян.',
  unsupportedBrowserMessage: 'Эта программа использует новейшие технологии. Они делают приложение более быстрым и удобным в работе. К сожалению, ваш браузер не поддерживает эти технологии.',
  uom: 'Ед. изм.',
  update: 'Обновить',
  updateFailed: 'Не удалось обновить ',
  updateIndex: 'Обновить Указатель',
  updatePassword: 'Обновить Пароль',
  updatePasswordMessage: 'Пароль успешно обновлен',
  updatePasswordPending: 'Ожидается сохранение после обновления пароля.',
  updated: 'Обновлено',
  upgradeBrowser: 'Обновите свой браузер.',
  upload: 'Загрузить',
  uploadComplete: 'Загрузка Завершена',
  uploadInProgress: 'Проходит Загрузка',
  uponSavingDisabledTenant: 'Если сохранить эту страницу и включить эту настройку, произойдет следующее:',
  uponSavingThisLicense: 'Сохранив эту страницу с данной настройкой, вы ДОЛЖНЫ ВЫПОЛНИТЬ СЛЕДУЮЩИЕ ДЕЙСТВИЯ:',
  upperBound: 'Максимальное Значение',
  untitledOrg: 'Организация без названия',
  usCurrency: 'дол. США',
  useDefaultCartColumns: 'Используйте Колонки Корзины По умолчанию',
  useDefaultPartsListColumns: 'Используйте Колонки Списка Деталей По умолчанию',
  user: 'Пользователь',
  userAddress: 'Адрес Пользователя | Адреса Пользователя',
  userCount: 'Количество Пользователей',
  userEditable: 'Редактирование Пользователем разрешено',
  userExpirationDate: 'Срок действия для пользователя',
  userExpirationDateInvalid: 'Необходимо указать срок действия для пользователя',
  userGroup: 'Группа Пользователей | Группы Пользователей',
  userGroupEmpty: 'Поле Групп Пользователя не должно быть пустым',
  userGroupType: 'Тип группы пользователей ',
  userGroupUniqueMessage: 'Это имя участника должно отличаться от других имен в Группе Пользователей',
  userGroupUsers: 'Пользователи в Группе Пользователей',
  username: 'Имя пользователя',
  usernameEmpty: 'Имя пользователя должно быть заполнено',
  users: 'Пользователи',
  usersWillReceiveAnUnauthorizedError: 'При попытке войти в учетную запись участника пользователи получат ошибку «Несанкционированный».',
  useSniForSsoMetadataRetrieval: 'Использовать SNI для получения метаданных SSO',
  value: 'Значение',
  validate: 'Проверить',
  validateTenantDirectories: {
    buttonLabel: 'Проверить каталоги содержимого',
    error: 'Не удалось проверить каталоги',
    success: 'Каталоги участников проверены'
  },
  variantSKU: 'Вариант SKU',
  validationMessage: 'Сообщение Проверки | Сообщения Проверки',
  valueGteError: 'Значение должно быть меньше или равно {max}',
  valueLteError: 'Значение должно быть больше или равно {min}',
  vendorName: 'Поставщик услуг',
  verifyPassword: 'Подтвердить Пароль',
  vertical: 'Вертикальный',
  version: 'Версия',
  video: 'Видео | Видео',
  view: 'Просмотр',
  viewAll: 'Просмотреть все',
  viewAllPagesAndBooks: 'Просмотреть все страницы и книги',
  viewAllResults: 'Просмотреть все результаты',
  viewBooks: 'Просмотреть книги',
  viewMore: 'Увидеть больше',
  viewPages: 'Просмотреть страницы',
  viewModeList: 'Список',
  viewModeTable: 'Таблица',
  visibility: 'Видимость',
  visibilityDescription: 'Кто может просматривать {entity}',
  visible: 'Видимый',
  waitForUpload: 'Пожалуйста, подождите, пока закончится загрузка.',
  watermark: 'Водяной знак',
  watermarkFont: 'Шрифт водяного знака',
  watermarkOpacityPercentage: 'Непрозрачность водяного знака (в %)',
  watermarkOrientation: 'Ориентация водяного знака',
  watermarkSaved: 'Водяной знак сохранен',
  watermarkSaveFailed: 'Не удалось сохранить водяной знак.',
  watermarkSelectionRequired: 'Необходимо ввести данные в поля «Имя пользователя» и «Выбор текста».',
  watermarkText: 'Текст водяного знака',
  wed: 'Ср',
  whereUsed: 'Где Используется',
  whoCanViewComment: 'Кто может видеть этот комментарий?',
  wholesale: 'Опт',
  wholesalePrice: 'Оптовая Цена',
  wholesaleTotal: 'Суммарная Оптовая Цена',
  widget: 'Виджет',
  widgetAuthHeader: 'Значение авторизации виджета',
  widgetKey: 'Ключ виджета | Ключи виджетов',
  widgetKeyAdmin: 'Администратор ключа виджета',
  widgetKeyCopied: 'Значение авторизации виджета скопировано в буфер обмена',
  widgetKeyDeleted: 'Ключ виджета удален',
  widgetKeyDeletedError: 'При удалении ключа виджета возникла ошибка',
  widgetKeyDeleteMsg: 'Удаление ключа виджета отключит его использование',
  widgetKeyManager: 'Управляющий ключом виджета',
  widgetKeyNameEmpty: 'Укажите уникальное имя. Оно будет использоваться, помимо прочего, для целей отчетности и ведения журнала в качестве имени пользователя при любой активности виджета.',
  widgetKeyRegenError: 'При повторной генерации ключа виджета возникла ошибка',
  widgetKeyRegenMsg: 'Повторная генерация ключа виджета может привести к отключению существующих интеграций',
  widgetKeyRegenTitle: 'Повторно сгенерировать ключ виджета?',
  widgetKeySaved: 'Ключ виджета сохранен',
  widgetPermissions: 'Для пользователей виджетов предусмотрен только режим чтения, а доступом к функциям можно управлять с помощью конфигурации на уровне виджета. Разрешения для стандартной группы пользователей не распространяются на пользователей виджетов, в отличии от прав доступа к медийной категории. Настройте управление доступом к медийной категории.',
  widgetRefreshErrorMsg: "Обновите страницу, чтобы просмотреть запрашиваемое содержимое",
  widgetUserGroup: 'Группа пользователей виджетов',
  widgetUserGroupDeleteMsg: 'Не удалось удалить группу виджетов с пользователями',
  willBeAffected: 'будет затронут(о).',
  willBeDeleted: '{content} будет удален(о).',
  willBeInsertedIntoItem: '{name} будет вставлено в {item}',
  willBeUpdated: 'будет обновлено.',
  zipCode: 'Почтовый Индекс',
  zoomHotpointToCanvas: 'Увеличить Ссылку-якорь до размера Холста',
  zoomIn: 'Увеличение',
  zoomOut: 'Уменьшение'
}
/* eslint-enable max-len */
