import http from '@/http'
import { Params, SchedulerJobState } from '@/interfaces/admin'
import { draftPageHasJobScheduler } from '@/helpers'
import { getDraftPage } from './getDraftPage'

export enum DraftFieldType {
  HOTPOINT_OR_HOTPOINT_LINK = "HOTPOINT_OR_HOTPOINT_LINK",
  PAGE_PART_TAG_VALUE = "PAGE_PART_TAG_VALUE",
  PART_TAG_VALUE = "PART_TAG_VALUE",
  QUANTITY = "QUANTITY",
  TRANSLATION_DESCRIPTION = "TRANSLATION_DESCRIPTION",
  UOM = "UOM",
  ITEM = "ITEM",
  SUPERSEDED_PART = "SUPERSEDED_PART"
}

export enum DraftFieldSeverity {
  ERROR = "ERROR",
  WARNING = "WARNING"
}

export interface DraftFieldError {
  refKey: String,
  item: string
  fieldType: DraftFieldType
  partNumber: string,
  severity: DraftFieldSeverity,
  name: string|null,
  lang: string|null,
  instanceId: number,
  supersededPartNumber?: string
}

export async function getDraftPublishStatus (pageId: number, params: Params): Promise<SchedulerJobState> {
  try {
    const draft = await getDraftPage(pageId, params)
    return draft?.schedulerJobState ?? SchedulerJobState.COMPLETED
  } catch (e) {
    console.log(e)
    return SchedulerJobState.COMPLETED
  }
}

export async function isPublishingDraftPage (pageId: number, params: Params): Promise<boolean> {
  const { data } = await http.get(`/admin/draft-pages/pages/${pageId}`, { params })
  const isPublishing = draftPageHasJobScheduler(data)
  return isPublishing
}

export async function publishCurrentDraft (id: number): Promise<string> {
  const { data } = await http.post(`/admin/draft-pages/${id}/publish/async`)
  return data
}

export async function turboPublishCurrentDraft (id: number): Promise<string> {
  const { data } = await http.post(`/admin/draft-pages/${id}/turbo-publish/async`)
  return data
}

export async function validateDraftPage (id: number): Promise<Array<DraftFieldError>> {
  const { data } = await http.get(`/admin/draft-pages/${id}/validate`)
  return data
}
