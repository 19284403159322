import http from '@/http'
import i18n from '@/locales'
import router from '@/router'
import { asyncFetcher } from '@/store'
import qs from 'qs'
import { notify } from "@kyvg/vue3-notification"
import { cloneDeep } from "lodash"
import { isEmailValid } from '@/helpers'
import { postError } from '@/helpers/notification'

const ORDER_RECEIVED = 'ORDER_RECEIVED'
const ORDER_TEMPLATE_RECEIVED = 'ORDER_TEMPLATE_RECEIVED'
const ORDER_PARTS_RECEIVED = 'ORDER_PARTS_RECEIVED'
const ORDER_UPDATE_FIELD_VALUE = 'ORDER_UPDATE_FIELD_VALUE'
const ORDER_UPDATE_LOCAL_FIELD_VALUE = 'ORDER_UPDATE_LOCAL_FIELD_VALUE'
const ORDER_LOADING = 'ORDER_LOADING'
const ORDER_LOADED = 'ORDER_LOADED'
const ORDER_UPDATING = 'ORDER_UPDATING'
const ORDER_UPDATED = 'ORDER_UPDATED'
const SET_EMAIL_INPUT = "SET_EMAIL_INPUT"

const state = {
  info: null,
  fields: {},
  layout: [],
  partColumns: [],
  parts: {},
  data: {},
  localData: {},
  hasPricing: false,
  message: '',
  isLoaded: false,
  isUpdating: false,
  emailInputs: {}
}

const getters = {
  editableFields: (state) =>
    Object.values(state.fields).filter((field) => field.editable),
  requiredFields: (state) =>
    Object.values(state.fields).filter((field) => field.required),
  isEveryReqFieldValid(state, getters) {
    const requiredFieldsSansEmail = getters.requiredFields
      .filter(field => field.type !== "EMAIL_LIST")

    return requiredFieldsSansEmail.every((field) => {
      const data = state.data[field.key]
      if (Array.isArray(data) && !data.length) {
        return getters.isEveryReqEmailValid && false
      } else if (data === null || data === '' || data === undefined) {
        return getters.isEveryReqEmailValid && false
      }
      return getters.isEveryReqEmailValid && true
    })
  },
  // Email requires special handling to check for input in the field
  isEveryReqEmailValid(state, { requiredFields, getEmailInput }) {
    const requiredEmailFields = requiredFields
      .filter(field => field.type === "EMAIL_LIST")
    return requiredEmailFields.every(field => {
      const emailKey = field.key
      const input = getEmailInput(emailKey) ?? ""
      const hasSavedEmail = !!state.data[emailKey]?.length ?? false
      if (!input && !hasSavedEmail) return false

      const emailInputFieldIsValid = isEmailInputValid(input)
      return hasSavedEmail || emailInputFieldIsValid
    })
  },
  IsEveryEmailValid(state, getters) {
    return !Object.values(getters.emailValidityChecks)
      .includes(false) && getters.isEveryReqEmailValid
  },
  canSubmitOrder(state, getters) {
    return getters.isEveryReqFieldValid && !state.isUpdating && getters.IsEveryEmailValid
  },
  getEmailInput(state) {
    return function(key) {
      return state.emailInputs[key] ?? ""
    }
  },
  emailValidityChecks(state) {
    const obj = {}
    for (let key in state.emailInputs) {
      const input = state.emailInputs[key] ?? ""
      obj[key] = isEmailInputValid(input)
    }
    return obj
  }
}

function isEmailInputValid(input) {
  return !!input.length ? isEmailValid(input) : true
}

const actions = {
  async getOrder ({ commit }, orderId) {
    try {
      commit(ORDER_LOADING)
      const [result1, result2, result3, result4] = await Promise.all([
        http.get(`orders/${orderId}`),
        http.get(`orders/${orderId}/template`),
        http.get(`orders/${orderId}/headers`),
        http.get(`orders/${orderId}/items`)
      ])

      if ((result4.data.itemsRemoved > 0)) {
        notify({
          title: i18n.global.t('success'),
          text: i18n.global.t('cartLoadedWithItemsRemoved', { count: result4.data.itemsRemoved }),
          type: 'success',
          duration: 5000
        })
      }

      commit(ORDER_RECEIVED, { order: result1.data })
      commit(ORDER_TEMPLATE_RECEIVED, {
        fields: result2.data.headers,
        layout: result2.data.layout,
        partColumns: result2.data.columns,
        data: result3.data,
        localData: {}
      })
      commit(ORDER_PARTS_RECEIVED, { parts: result4.data })
    } catch (err) {
      console.error(err)
      await router.replace({ name: 'Orders' })
    }
  },
  async newOrder ({ dispatch, commit }, { type }) {
    try {
      commit(ORDER_LOADING)
      const { status, data } = await http.get(`orders/templates/defaults/${type}`)

      if (status === 204) {
        await dispatch('submitOrder', { type })
      } else {
        const localData = await dispatch('getLocalData')

        commit(ORDER_RECEIVED, { order: null })
        commit(ORDER_TEMPLATE_RECEIVED, {
          fields: data.headers,
          layout: data.layout,
          partColumns: data.columns,
          data: {},
          localData: localData || {}
        })
      }
    } catch (err) {
      commit(ORDER_UPDATED)
      const response = err.response
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      })
      await dispatch('navigateToCart')
    }
  },
  async submitOrder ({ commit, state, dispatch, rootState, rootGetters }, { type }) {
    try {
      commit(ORDER_UPDATING)
      const orderData = {}
      Object.keys(state.fields).forEach((key) => {
        const field = state.fields[key]
        if (!field) return

        if (field.type === 'ADDRESS') {
          orderData[key] = state.data[key] || {}
        } else if (field.type === 'EMAIL_LIST') {
          orderData[key] = state.data[key] || []
        } else {
          orderData[key] = state.data[key] || ''
        }
      })

      await asyncFetcher(
        `orders/${type}-async`,
        orderData,
        null,
        'post',
        'orders/async-poll',
        null,
        1000,
        1000,
        600000,
        async errMsg => {
          let errResponse// Expected to support tenant labels

          if (rootState.user.erpEnabled && rootGetters['user/hasCartSendToErp']) {
            errResponse = String(errMsg)
          } else if (type === 'order') {
            const item = i18n.global.tc('order', 1).toLocaleLowerCase()
            errResponse = i18n.global.t('failedToSubmitItem', { item: item })
          } else {
            const item = i18n.global.t('requestForQuote').toLocaleLowerCase()
            errResponse = i18n.global.t('failedToSubmitItem', { item: item })
          }

          postError({
            title: i18n.global.t('error'),
            text: errResponse,
          })
          commit(ORDER_UPDATED)
          await dispatch('navigateToCart')
        },
        async (resp) => {
          try {
            if (resp.data.redirect) {
              window.location.href = resp.data.redirect
              commit(ORDER_UPDATED)
            } else {
              await commit(ORDER_RECEIVED, { order: resp.data.orderDto, message: resp.data.successMessage || '' })
              await dispatch('cart/getCart', null, { root: true })
              await router.push({ name: 'OrderSubmitted' })
              commit(ORDER_UPDATED)
            }
          } catch (err) {
            const errResponse = err.response
            postError({
              title: i18n.global.t('error'),
              text: errResponse ? errResponse.data.message : err,
            })
            commit(ORDER_UPDATED)
            await dispatch('navigateToCart')
          }
        }
      )
    } catch (err) {
      const response = err.response
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      })
      commit(ORDER_UPDATED)
      await dispatch('navigateToCart')
    }
  },
  async updateOrder ({ state, dispatch, commit }, { status, comments }) {
    try {
      commit(ORDER_UPDATING)
      const api = []
      if (status && status !== state.info.statusCode) {
        let dto = {
          status: status,
          comments: comments
        }

        api.push(http.post(`orders/${state.info.id}/status`, dto))
      }
      const orderData = {}
      Object.keys(state.fields).forEach((key) => {
        const field = state.fields[key]
        if (!field) return

        if (field.type === 'ADDRESS') {
          orderData[key] = state.data[key] || {}
        } else if (field.type === 'EMAIL_LIST') {
          orderData[key] = state.data[key] || []
        } else {
          orderData[key] = state.data[key] || ''
        }
      })
      api.push(http.put(`orders/${state.info.id}/headers`, orderData))
      await Promise.all(api)
      await dispatch('getOrder', state.info.id)
      commit(ORDER_UPDATED)
    } catch (err) {
      commit(ORDER_UPDATED)
      const response = err.response
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      })
    }
  },
  async getOrderHeadersWithReturn (context, orderId) {
    let order
    try {
      const { data } = await http.get(`orders/${orderId}/template`)
      order = data.headers
    } catch (err) {
      // na
    }
    return order
  },
  async getOrderHistoryWithReturn (context, orderId) {
    let history
    try {
      const { data } = await http.get(`orders/${orderId}/history`)
      history = data
    } catch (err) {
      // na
    }
    return history
  },
  async updateOrderItemQuantity ({ state, commit }, { item, qty }) {
    try {
      const payload = qs.stringify({
        partId: item.partId,
        qty: qty,
        mediaId: item.mediaId,
        chapterId: item.chapterId,
        subChapterId: item.subChapterId,
        pageId: item.pageId
      })

      const data = await asyncFetcher(`orders/${state.info.id}/items-async`, payload,
        null, 'put', `orders/${state.info.id}/items-async-poll`,
        'cartDto', 1000, 1000, 600000,
        (message) => {
          postError({
            title: i18n.global.t('error'),
            text: message,
          })
        })

      commit(ORDER_PARTS_RECEIVED, { parts: data })
    } catch (err) {
      // na
    }
  },
  async addItemToOrder ({ state, commit }, { part, qty }) {
    try {
      const payload = qs.stringify({
        partId: part.entityId, // entityId from QuickAdd
        qty: qty,
        mediaId: part.mediaId,
        chapterId: part.chapterId,
        subChapterId: part.subChapterId,
        pageId: part.pageId
      })

      const { data } = await http.post(`orders/${state.info.id}/items`, payload)
      commit(ORDER_PARTS_RECEIVED, { parts: data })
    } catch (err) {
      // na
    }
  },
  async removeOrderItem ({ state, commit }, { item }) {
    try {
      const formData = new FormData()
      formData.append('partId', item.partId)
      formData.append('qty', 0)

      const { data } = await http.put(`orders/${state.info.id}/items`, formData)
      commit(ORDER_PARTS_RECEIVED, { parts: data })
    } catch (err) {
      // na
    }
  },
  async updateFieldValue ({ commit, dispatch, state }, { field, value, updateLocal, canSaveLocal }) {
    await commit(ORDER_UPDATE_FIELD_VALUE, { field, value })

    /**
     * Reused between editable PO/RFQ and submitted, non-editable orders.
     * Local storage needs to update only on editable forms to keep data
     * accurate.
     */
    if (canSaveLocal) {
      if (updateLocal) { // Preserve new field-value
        await commit(ORDER_UPDATE_LOCAL_FIELD_VALUE, { field, value })
      } else { // Drop field-value
        await commit(ORDER_UPDATE_LOCAL_FIELD_VALUE, { field, undefined })
      }

      await dispatch('setLocalStorage', { key: 'order', value: state.localData }, { root: true })
    }
  },
  async getLocalData ({ dispatch, state }) {
    if (!state.localData || !Object.keys(state.localData).length) {
      // eslint-disable-next-line no-return-await
      return await dispatch('getLocalStorage', { key: 'order' }, { root: true })
    }
    return state.localData
  },
  async navigateToPurchaseOrder ({ dispatch }) {
    // Ensures call happens upon navigation only
    await dispatch('cart/getCart', null, { root: true })
    await router.push({ name: 'PurchaseOrder' })
  },
  async navigateToRequestForQuote ({ dispatch }) {
    // Ensures call happens upon navigation only
    await dispatch('cart/getCart', null, { root: true })
    await router.push({ name: 'RequestForQuote' })
  },
  async navigateToCart () {
    await router.replace({ name: 'Cart' })
  },
  async navigateToOrders (replace = false) {
    if (replace) {
      await router.replace({ name: 'Orders' })
    } else {
      await router.push({ name: 'Orders' })
    }
  },
  setEmailInput({ commit }, payload) {
    commit(SET_EMAIL_INPUT, payload)
  }
}

const mutations = {
  [ORDER_TEMPLATE_RECEIVED] (state, { fields, layout, partColumns, data, localData, message }) {
    state.fields = fields
    state.layout = layout
    state.localData = localData
    state.message = message || ''

    const values = Object.assign({}, data)
    Object.keys(fields).forEach((key) => {
      const field = fields[key]
      const value = data[field.key] || ''
      const local = localData[field.key] || ''
      const defaultValue = field.defaultValue || ''

      if (value || local || defaultValue) {
        values[field.key] = value || local || defaultValue
      }
    })
    state.data = values

    const columns = []
    if (partColumns.length) {
      partColumns.forEach((col) => {
        switch (col.field) {
          case 'partNumber':
          case 'supplier':
            break
          // PART DATA
          case 'part':
            columns.push({ property: 'name' })
            break
          case 'name':
            columns.push({ property: 'name' })
            break
          case 'qty':
            columns.push({ property: 'qty' })
            break
          case 'uom':
            columns.push({ property: 'uom' })
            break
          // BOOK DATA
          case 'book':
            columns.push({ property: 'book' })
            break
          case 'chapter':
            columns.push({ property: 'chapter' })
            break
          case 'subChapter':
            columns.push({ property: 'sub-chapter' })
            break
          case 'page':
            columns.push({ property: 'page' })
            break
          // PRICING
          case 'retailPrice':
            state.hasPricing = true
            columns.push({ property: 'retailPrice', type: 'price' })
            break
          case 'totalRetailPrice':
            columns.push({ property: 'totalRetailPrice', type: 'total' })
            break
          case 'discountedPrice':
            state.hasPricing = true
            columns.push({ property: 'discountedPrice', type: 'price' })
            break
          case 'totalDiscountedPrice':
            columns.push({ property: 'totalDiscountedPrice', type: 'total' })
            break
          case 'wholesalePrice':
            state.hasPricing = true
            columns.push({ property: 'wholesalePrice', type: 'price' })
            break
          case 'totalWholesalePrice':
            columns.push({ property: 'totalWholesalePrice', type: 'total' })
            break
          // OTHER
          case 'availability':
            columns.push({ property: 'availability' })
            break
          case 'eta':
            columns.push({ property: 'eta' })
            break
          default:
            columns.push({ property: col.field.split(':')[1], type: 'tag', name: col.name })
        }
      })
    }
    columns.push({ property: 'actions' })

    state.partColumns = columns
    state.isLoaded = true
  },
  [ORDER_RECEIVED] (state, { order }) {
    state.info = order
  },
  [ORDER_PARTS_RECEIVED] (state, { parts }) {
    state.parts = parts
  },
  [ORDER_UPDATE_FIELD_VALUE] (state, { field, value }) {
    state.data[field.key] = value
  },
  [ORDER_UPDATE_LOCAL_FIELD_VALUE] (state, { field, value }) {
    if (value !== null && value !== undefined) {
      state.localData[field.key] = value
    } else {
      delete state.localData[field.key]
    }
  },
  [ORDER_LOADING] (state) {
    state.isLoaded = false
  },
  [ORDER_LOADED] (state) {
    state.isLoaded = true
  },
  [ORDER_UPDATING] (state) {
    state.isUpdating = true
  },
  [ORDER_UPDATED] (state) {
    state.isUpdating = false
  },
  [SET_EMAIL_INPUT] (state, { key, value }) {
    // This approach is necessary for getters to react to changes
    const deepCopy = cloneDeep(state.emailInputs)
    deepCopy[key] = value
    state.emailInputs = deepCopy
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
