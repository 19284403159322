function expandPropsSchema (props) {
  const primTypes = [String, Array, Boolean, Number]
  const objs = Object.keys(props).map((jsName) => {
    const value = props[jsName]
    if (primTypes.includes(value)) {
      return { jsName, type: value }
    }

    if (typeof value === 'object' && value instanceof Array) {
      return { jsName, type: Array, options: value }
    }

    return { jsName, ...value }
  })

  const withServerNames = objs.map((obj) => {
    const name = obj.jsName.replace(/([A-Z])/g, '_$1').toUpperCase()
    return { name, ...obj }
  })

  return withServerNames.reduce((acc, el) => ({
    ...acc,
    [el.jsName]: el
  }), {})
}

/**
 * Beware of properties with 'Not Selected' or null-like options. Properties
 * can be nullable in the database, and HTML-dropdowns may not map the value
 * to an option.
 */
const properties = {
  tenant: expandPropsSchema({
    analyticsJSON: {
      name: 'ANALYTICS_JSON',
      type: String
    },
    webLogo: String,
    defaultSupplierKey: {
      type: Array,
      optionsSource: 'suppliers'
    },
    defaultTocDisplay: Boolean,
    defaultPartsListLayout: ['horizontal', 'vertical'],
    defaultLocale: {
      type: Array,
      optionsSource: 'locales'
    },
    defaultDiagramNavigatorStateOpen: {
      name: 'DEFAULT_DIAGRAM_NAVIGATOR_STATE',
      type: Boolean,
      trueValue: 'open',
      falseValue: 'closed'
    },
    overrideSvgOpacityValue: {
      type: Number,
      onRead: (v) => Math.floor(v * 100),
      onWrite: (v) => v / 100.0,
      min: 0,
      max: 100
    },
    partOrderSuggestionsMinThreshold: {
      type: Number,
      min: 1
    },
    ssoIdpMetadataUrl: String,
    ssoSamlDnAttributeName: String,
    ssoSamlMemberofAttributeName: String,
    // Deprecated
    shoppingcartPriceColumn1: ['none', 'retail', 'discounted', 'wholesale'],
    shoppingcartPriceColumn2: ['none', 'retail', 'discounted', 'wholesale'],
    shoppingcartPriceColumn3: ['none', 'retail', 'discounted', 'wholesale'],
    calloutBehavior: ['static', 'dynamic', 'custom'],
    printLogo: String,
    erpRetailPriceMappedFrom: String,
    erpWholesalePriceMappedFrom: String,
    erpDiscountedPriceMappedFrom: String,
    tagRangeMaxValue: {
      type: Number,
      min: 1
    },
    erpAvailabilityMappedFrom: String,
    erpEtaMappedFrom: String,
    calloutZoomBehavior: Boolean,
    displayDefaultImagePart: Boolean,
    defaultImagePart: String,
    shoppingCartAddToCartQtyBehavior: ['qtyZero', 'qtyOne', 'qtyFromBom'],
    dynamicNameFormatChapter: String,
    dynamicNameFormatPage: String,
    dynamicNameFormatPart: String,
    ssoSamlEmailAttributeName: String,
    currencyDefaultCode: {
      type: Array,
      optionsSource: 'currencyCodes'
    },
    erpCurrencyCodeMappedFrom: String,
    customHelpUri: String,
    publisherDefaultUom: String,
    maxNumFieldsForIndexing: Number,
    clearContactMediaOwnerFromField: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    shelfFiltersDisplayOrganizations: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    shelfFiltersDisplayMediaName: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    shelfFiltersDisplayMediaDescription: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    shelfFiltersDisplayMediaIdentifier: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    shelfFiltersDisplayPartName: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    shelfFiltersDisplayPartNumber: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    publishingContactEmail: String,
    performanceLogging: { type: Boolean, trueValue: 'on', falseValue: 'off' },
    ecommerceLogo: String,
    ecommerceUseExternalOrderNumber: Boolean,
    useSniForSsoMetadataRetrieval: Boolean,
    indexingEnabled: Boolean,
    htmlUiUserSwitchEnabled: Boolean,
    htmlPartsListRealTimeInormationEnabled: Boolean /* sic */,
    heroImage: String,
    cssBrandPrimary: String,
    cssHotpoint: String,
    cssHotpointSelected: String,
    cssHotpointHighlighted: String,
    cssPrimaryButton: String,
    cssSecondaryButton: String,
    cssBackground: String,
    cssLinks: String,
    loginBackgroundLogo: String,
    tenantXsltFile: String,
    collapseTocFeature: {
      name: 'COLLAPSE_TOC_FEATURE_SANDVIK_ONLY',
      type: Boolean
    },
    cssCalloutText: String,
    indexLane: Number,
    publisherLane: Number,
    printerLane: Number,
    exportLane: Number,
    tenantType: ['none', 'production', 'demo', 'sandbox', 'm2m'],
    indexIncludeTimestamp: Boolean,
    publisherAlwaysReplaceHotpointLinks: Boolean,
    tenantReindexModulus: Number,
    defaultSortMode: [
      'relevance',
      'name_asc', 'name_desc',
      'identifier_asc', 'identifier_desc',
      'description_asc', 'description_desc',
      'searchable_type_asc', 'searchable_type_desc',
      'updated_asc', 'updated_desc'
    ],
    defaultSearchMode: ['contains', 'exact'],
    doErpForQuotes: Boolean,
    parseAddressForType: Boolean,
    ecommerceUseExtOrder: {
      type: Boolean,
      name: 'ECOMMERCE_USE_EXTERNAL_ORDER_NUMBER'
    },
    overrideFacetLimitValue: {
      type: Number,
      min: -1,
      max: 10000000
    },
    hotpointlinkPageInBookOnly: Boolean,
    enableChapterIndexing: {
      name: 'CHAPTER_INDEXING_ENABLED',
      type: Boolean
    },
    enableIndexing: {
      name: 'INDEXING_ENABLED',
      type: Boolean
    },
    lockPartTranslationsWhenPublishing: {
      name: 'LOCK_PART_TRANSLATIONS_WHEN_PUBLISHING',
      type: Boolean
    },
    exportPageFileNameAsHashKey: {
      name: 'EXPORT_PAGE_FILE_NAME_AS_HASH_KEY',
      type: Boolean
    },
    userPwdExpirationDays: {
      type: Number,
      name: 'USER_PWD_EXPIRATION_DAYS',
      min: 1,
      max: 9999
    },
    deleteMissingTranslationsOnPublish: {
      name: 'DELETE_MISSING_TRANSLATIONS_ON_PUBLISH',
      type: Boolean
    },
    overrideHotpointScaleValue: {
      type: Number,
      min: 0,
      max: 100
    },
    staticHotpointSize: Boolean,
    exportFromSearch: Boolean,
    zoomHotpointToCanvasSize: Boolean,
    accessControlsMediaCategoriesUpdateMediaTimestamp: Boolean,
    successNotificationDuration: {
      name: 'ADD_TO_CART_TOAST_DURATION_SECONDS',
      type: Number,
      min: 0,
      max: 10
    },
    showSearchButton: {
      name: 'HIDE_SEARCH_BUTTON',
      type: Boolean,
      trueValue: 'false',
      falseValue: 'true'
    },
    showIdentifierInSearch: {
      name: 'HIDE_IDENTIFIER_IN_SEARCH',
      type: Boolean,
      trueValue: 'false',
      falseValue: 'true'
    },
    hideBookInSearchAndRecent: {
      name: 'HIDE_BOOKS_IN_LIBRARY',
      type: Boolean,
      trueValue: 'true',
      falseValue: 'false'
    },
    hidePageInSearchAndRecent: {
      name: 'HIDE_PAGES_IN_LIBRARY',
      type: Boolean,
      trueValue: 'true',
      falseValue: 'false'
    },
    hidePartInSearchAndRecent: {
      name: 'HIDE_PARTS_IN_LIBRARY',
      type: Boolean,
      trueValue: 'true',
      falseValue: 'false'
    },
    showPartInformationBanner: {
      name: 'SHOW_PART_INFORMATION_BANNER',
      type: Boolean,
      trueValue: 'true',
      falseValue: 'false'
    },
    showWhereUsedCountInSearchBar: {
      name: 'SHOW_WHERE_USED_COUNT_IN_SEARCH_BAR',
      type: Boolean,
      trueValue: 'true',
      falseValue: 'false'
    },
    bulkIndexingLane: Number,
    ssoLoginSystem: String,
    hotpointZoomBehavior: {
      name: 'HOTPOINT_ZOOM_BEHAVIOR',
      type: Boolean,
      trueValue: 'on',
      falseValue: 'off'
    },
    currencyDisplaySymbol: Boolean,
    betaAccessKeys: {
      name: 'BETA_ACCESS_KEYS',
      type: String
    },
    debugPageBuilderPLZ: {
      name: 'DEBUG_PAGE_BUILDER_PLZ',
      type: Boolean
    },
    enablePLZToDraft: {
      name: 'ENABLE_PROCESS_PLZ_TO_DRAFT',
      type: Boolean
    },
    documotoLicensePackage: ['none', 'Essentials', 'Business', 'Professional', 'Enterprise'],
    disableForwardingReindexTargetMediaForDsw: Boolean,
    solrSlaveServersBaseUrlsOverride: {
      name: 'SOLR_SLAVE_SERVERS_BASE_URLS_OVERRIDE',
      type: String
    },
    erpPartInfoCacheDurationMinutes: Number,
    hourlyDWSRequestLimit: {
      name: 'HOURLY_DWS_REQUEST_LIMIT',
      type: Number
    },
    hourlyRESTRequestLimit: {
      name: 'HOURLY_REST_REQUEST_LIMIT',
      type: Number
    },
    partnerRESTRequestLimit: {
      name: 'PARTNER_HOURLY_REST_REQUEST_LIMIT',
      type: Number
    },
    tenantWebserviceUser: String,
    publisherManualLane: Number,
    ssoLoginMaxAuthenticationAgeSeconds: Number,
    enableDocumotoWidgetLogo: Boolean,
    enableIframeSupport: Boolean,
    // To match ENABLE_3D_FEATURES enum, an _ is needed before 3d
    enable_3dFeatures: Boolean,
    disableLibraryBannerImage: Boolean,
  }),
  organization: expandPropsSchema({
    overrideSvgOpacityValue: {
      type: Number,
      onRead: (v) => Math.floor(v * 100),
      onWrite: (v) => v / 100.0,
      min: 0,
      max: 100
    },
    shoppingcartPriceColumn1: ['none', 'retail', 'discounted', 'wholesale'],
    shoppingcartPriceColumn2: ['none', 'retail', 'discounted', 'wholesale'],
    shoppingcartPriceColumn3: ['none', 'retail', 'discounted', 'wholesale'],
    webLogo: String,
    printLogo: String,
    erpRetailPriceMappedFrom: String,
    erpWholesalePriceMappedFrom: String,
    erpDiscountedPriceMappedFrom: String,
    erpAvailabilityMappedFrom: String,
    erpEtaMappedFrom: String,
    currencyDefaultCode: {
      type: Array,
      optionsSource: 'currencyCodes'
    },
    erpCurrencyCodeMappedFrom: String,
    shoppingCartAddToCartQtyBehavior: ['qtyZero', 'qtyOne', 'qtyFromBom'],
    ecommerceLogo: String,
    heroImage: String,
    cssBrandPrimary: String,
    cssHotpoint: String,
    cssHotpointSelected: String,
    cssHotpointHighlighted: String,
    cssPrimaryButton: String,
    cssSecondaryButton: String,
    cssBackground: String,
    cssLinks: String,
    loginBackgroundLogo: String,
    cssCalloutText: String,
    ignoreAccessControls: ['ignore', 'enforce'],
    enforceAccessControlsForQuickAdd: ['ignore', 'enforce']
  })
}

const lookup = Object.keys(properties).reduce((pAcc, pEl) => ({
  ...pAcc,
  [pEl]: Object.keys(properties[pEl]).reduce((acc, el) => {
    const desc = properties[pEl][el]
    const hasOverrideName = !!desc && !!desc.name
    const autoName = el.replace(/([A-Z])/g, '_$1').toUpperCase()
    const name = hasOverrideName ? desc.name : autoName
    return { ...acc, [name]: el }
  }, {})
}), {})

function readBoolean (value, trueValue, falseValue) {
  switch (value) {
    case trueValue: return true
    case falseValue: return false
    default: return null
  }
}

function writeBoolean (value, trueValue, falseValue) {
  switch (value) {
    case true: return trueValue
    case false: return falseValue
    default: return null
  }
}

/* eslint-disable no-invalid-this */
export async function loadProperties (url, schema, opts) {
  const propsSchema = properties[schema]
  const propsLookup = lookup[schema]

  const { data } = await this.$rest.get(url)

  if (opts.logPayloads) {
    //console.log('loadProperties.data', data)
  }

  const result = Object.keys(data).reduce((acc, serverName) => {
    const value = data[serverName]
    const jsName = propsLookup[serverName]
    const desc = propsSchema[jsName]

    if (value === null || value === 'null') {
      return {
        ...acc,
        [jsName]: null
      }
    }

    if (!desc) {
      //console.log(`invalid server name detected: ${serverName}`)
      return acc
    }

    switch (desc.type) {
      case String: {
        return { ...acc, [jsName]: value }
      }
      case Number: {
        let num = parseFloat(value)
        const { min, max, onRead } = desc

        if (typeof onRead === 'function') {
          num = onRead(num)
        }

        if (typeof min === 'number' && min > num) {
          num = min
        }

        if (typeof max === 'number' && max < num) {
          num = max
        }

        return {
          ...acc,
          [jsName]: num
        }
      }
      case Boolean: {
        const trueValue = desc.trueValue || 'true'
        const falseValue = desc.falseValue || 'false'

        return {
          ...acc,
          [jsName]: readBoolean(value, trueValue, falseValue)
        }
      }
      case Array: {
        const { options, optionsSource } = desc

        if (typeof options !== 'undefined' && value !== null && !options.includes(value)) {
          console.error(`encountered invalid value from server (jsName=${jsName}, value=${value})`)
          return acc
        }

        if (typeof optionsSource !== 'undefined') {
          // Do Nothing for now.
        }

        return { ...acc, [jsName]: value }
      }
    }
    return {}
  }, {})

  if (opts.logPayloads) {
    //console.log('loadProperties.result', result)
  }

  return result
}

export async function saveProperties (url, schema, props, opts) {
  const propsSchema = properties[schema]

  if (opts.logPayloads) {
    //console.log('saveProperties.props', props)
  }

  const payload = Object.keys(props).reduce((acc, jsName) => {
    //console.log('(props).reduce acc = ', acc, ' jsName = ', jsName)
    const desc = propsSchema[jsName]
    const value = props[jsName]
    switch (desc.type) {
      case String: {
        return { ...acc, [desc.name]: value }
      }
      case Number: {
        const { min, max, onWrite } = desc
        const isEmptyPwdExpiration = desc.name === "USER_PWD_EXPIRATION_DAYS" && !value
        if (value === null || isEmptyPwdExpiration) {
          return { ...acc, [desc.name]: null }
        }

        let num = value

        if (typeof min === 'number' && num < min) {
          num = min
        }

        if (typeof max === 'number' && num > max) {
          num = max
        }

        if (typeof onWrite === 'function') {
          num = onWrite(num)
        }

        return { ...acc, [desc.name]: `${num}` }
      }
      case Boolean: {
        const trueValue = desc.trueValue || 'true'
        const falseValue = desc.falseValue || 'false'

        return {
          ...acc,
          [desc.name]: writeBoolean(value, trueValue, falseValue)
        }
      }
      case Array: {
        const { options, optionsSource } = desc

        if (typeof options !== 'undefined' && value !== null && !options.includes(value)) {
          console.error(`encountered invalid value from client (jsName=${jsName}, value=${value})`)
          return acc
        }

        if (typeof optionsSource !== 'undefined') {
          // Do Nothing for now.
        }

        return { ...acc, [desc.name]: value }
      }
    }
    return {}
  }, {})

  if (opts.logPayloads) {
    //console.log('saveProperties.payload', payload)
  }

  return await this.$rest.put(url, payload)
}
/* eslint-enable no-invalid-this */

export default {
  tenantProperties: {
    methods: {
      async loadTenantProperties (tk, opts = {}) {
        const url = `/tenants/${tk}/properties`
        return await loadProperties.call(this, url, 'tenant', opts)
      },
      async saveTenantProperties (tk, props, opts = {}) {
        const url = `/tenants/${tk}/properties`
        return await saveProperties.call(this, url, 'tenant', props, opts)
      }
    }
  },
  organizationProperties: {
    methods: {
      async loadOrganizationProperties (id, opts = {}) {
        const url = `/organizations/${id}/properties`
        return await loadProperties.call(this, url, 'organization', opts)
      },
      async saveOrganizationProperties (id, props, opts = {}) {
        const url = `/organizations/${id}/properties`
        return await saveProperties.call(this, url, 'organization', props, opts)
      }
    }
  }
}
